import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Loader } from "../Loader";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useMyContext } from "../../context";
export const Sidebar = () => {
  const [activeLink, setActiveLink] = useState(() => {
    return localStorage.getItem("active") || "dashboard";
  });
  const navigate = useNavigate();
  const { state, dispatch } = useMyContext();

  const handleLinkClick = (type) => {
    setActiveLink(type);
    localStorage.setItem("active", type);
  };

  useEffect(() => {
    const storageListener = (e) => {
      if (e.key === "active") {
        setActiveLink(e.newValue);
      }
    };

    window.addEventListener("storage", storageListener);

    return () => {
      window.removeEventListener("storage", storageListener);
    };
  }, []);

  const [show, setShow] = useState(false);

  const handleShow = async (address) => {
    setShow(true);
  };
  const handleClose = () => setShow(false);

 const  handleClick = () => {
  if (state?.address) {
    const shouldProceed = window.confirm('Are you sure you want to go home page?');
    if (shouldProceed) {
      if (state?.address) {
        navigate('/login');
      }
    } else {
      navigate('/preview');
    }
  }
  };


  return (
    <>
   
      <div className="left_bar" onClick={handleClick}>
        <nav className="navbar2 ">
          <div className>
            <div className="collapse navbar-collapse" id="navbarText">
              <ul className="navbar-nav m-auto ">
                <li className="nav-item">
                  <Link
                    to="/dashboard"
                    className={`nav1 ${
                      activeLink === "dashboard" ? "active" : ""
                    }`}
                    onClick={() => handleLinkClick("dashboard")}
                  >
                    <i className="fa fa-home mr-2" /> Dashboard
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    to="/package"
                    className={`nav1 ${
                      activeLink === "package" ? "active" : ""
                    }`}
                    onClick={() => handleLinkClick("package")}
                  >
                    <i className="fa fa-home mr-2" /> Slots
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    to="/package-report"
                    className={`nav1 ${
                      activeLink === "package-report" ? "active" : ""
                    }`}
                    onClick={() => handleLinkClick("package-report")}
                  >
                    <i className="fa fa-trophy mr-2" />
                    Slot Report
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    to="/direct-report"
                    className={`nav1 ${
                      activeLink === "direct-report" ? "active" : ""
                    }`}
                    onClick={() => handleLinkClick("direct-report")}
                  >
                    <i className="fa fa-trophy mr-2" /> Direct Referral Reports
                  </Link>
                </li>

                {/* <li className="nav-item">
                      <Link to='/levelIncome-report' className={`nav1 ${
                    activeLink === "levelIncome-report" ? "active" : ""
                  }`} onClick={()=>handleLinkClick('levelIncome-report')}>
                        <i className="fa fa-trophy mr-2" /> Level Income Reports
                      </Link>
                    </li> */}

                <li className="nav-item drop2 dropdown">
                  <Link to="#" className="nav1" data-toggle="dropdown">
                    <i className="fa fa-user-o mr-2" /> Team
                    <i className="fa fa-angle-down" />
                  </Link>
                  <div className="dropdown-menu ">
                    <div className="manu_box">
                      <Link className="mh1" to="/partners">
                        <i className="fa fa-star-o mr-2" /> Partners
                      </Link>
                    </div>
                    {/* <div className="manu_box">
                      <Link to="#" className="mh1">
                        <i className="fa fa-calendar-check-o mr-2" /> Links
                      </Link>
                    </div>
                    <div className="manu_box">
                      <Link to="#" className="mh1">
                        <i className="fa fa-calendar-check-o mr-2" /> Stats
                      </Link>
                    </div> */}
                    <div className="manu_box">
                      <Link to="/tree/user" className="mh1">
                        <i className="fa fa-calendar-check-o mr-2" /> Tree
                      </Link>
                    </div>
                    <div className="manu_box">
                      <Link
                        to="/QuoreIncome-report"
                        // className={`nav1 ${
                        //   activeLink === "QuoreIncome-report" ? "active" : ""
                        // }`}
                        // onClick={() => handleLinkClick("QuoreIncome-report")}
                      >
                        <i className="fa fa-trophy mr-2" /> 11Qora Income
                        Reports
                      </Link>
                    </div>
                  </div>
                </li>

                {/* <li className="nav-item">
                      <Link to="#" className=" nav1">
                        <i className="fa fa-trophy mr-2" /> Marathon
                      </Link>
                    </li> */}
                {/* <li className="nav-item">
                  <Link to="#" className=" nav1">
                    <i className="fa fa-share-square-o mr-2" /> Social
                  </Link>
                </li> */}

                <li className="nav-item drop2 dropdown">
                  <Link to="#" className="nav1" data-toggle="dropdown">
                    <i className="fa fa-book mr-2" /> Information
                    <i className="fa fa-angle-down" />
                  </Link>
                  <div className="dropdown-menu">
                    {/* <div className="manu_box">
                          <Link to ='#' className="mh1">
                            {" "}
                            <i className="fa fa-star-o mr-2" /> Instruction
                          </Link>
                        </div> */}
                    <div className="manu_box">
                      <Link to='/calculator' className="mh1">
                        <i className="fa fa-calculator-check-o mr-2" />
                        Calculator
                      </Link>
                    </div>
                    {/* <div className="manu_box">
                          <Link to="#" className="mh1">
                            <i className="fa fa-calendar-check-o mr-2" />{" "}
                            Telegram bots
                          </Link>
                        </div> */}

                    {/* <div className="manu_box">
                          <Link to="#" className="mh1">
                            <i className="fa fa-calendar-check-o mr-2" /> Ideas
                          </Link>
                        </div> */}
                  </div>
                </li>

                <li className="nav-item">
                  <a
                    href="#"
                    className="nav1"
                    onClick={handleShow}
                    style={{ cursor: "pointer" }}
                  >
                    <i className="fa fa-file-text-o mr-2" /> Promo &amp; PDFs
                  </a>
                </li>
              </ul>
            </div>
            <div className="vertical-social  mt-4" data-wow-delay="0.1s">
              <ul>
                <li>
                  <Link
                    to="https://www.facebook.com/WWW.LifeEnjoy.io?mibextid=ZbWKwL"
                    target="_blank"
                  >
                    {" "}
                    <i className="fa fa-facebook" />
                  </Link>
                </li>
                <li>
                  <Link to="https://t.me/lifeenjoy100" target="_blank">
                    <i className="fa fa-telegram" />
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.youtube.com/@LifeEnjoy.io23"
                    target="_blank"
                  >
                    <i className="fa fa-youtube" />
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://instagram.com/lifeenjoy.io?igshid=NzZlODBkYWE4Ng=="
                    target="_blank"
                  >
                    <i className="fa fa-instagram" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Promo &amp; PDFs</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center">
              {/* <div className="col-6 mb-3"> Download Pdf </div> */}
              {/* /assets/pdf/pdf10_1.pdf */}
              <a
                className="btn btn-success mr-2"
                href="/assets/pdf/pdf10_1.pdf"
                download
              >
                Download Pdf
              </a>

              {/* <div className="col-6 mb-3"> {sponsorUserId} </div> */}

              {/* <div className="col-6 mb-3">Video Link </div> */}
              <Link
                className="btn btn-success"
                to="https://www.youtube.com/@LifeEnjoy.io23"
                target="_blank"
              >
                Video Link
              </Link>
              {/* <div className="col-6 mb-3">{sponsorIncome} USDT</div> */}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};
