import React, { useEffect, useState } from "react";
import { Loader } from "../Loader";
import { contractAddress, ContractAbi, infuraUrl } from "./Constant";
import { CopyRight } from "./CopyRight";
import PrePersonlLink from "../preview/PrePersonlLink";
import { Link, useNavigate } from "react-router-dom";

import Web3 from "web3";
import copy from "copy-to-clipboard";

import { PreTransactionList } from "../preview/PreTransactionList";

import { Button, Modal } from "react-bootstrap";
import { useMyContext } from "../../context";
import { PreTopbar } from "../preview/PreTopbar";
import { PreSidebar } from "../preview/PreSidebar";
// import { FiCopy } from "react-icons/fi";
export const Preview = () => {
  const { state } = useMyContext();

  const AllPackages = [
    { id: 1, Amount: 5, Name: "Start Up" },
    { id: 2, Amount: 10, Name: "Coral" },
    { id: 3, Amount: 20, Name: "Pearl" },

    { id: 4, Amount: 40, Name: "Emerald" },
    { id: 5, Amount: 80, Name: "Sapphire" },
    { id: 6, Amount: 160, Name: "Ruby" },

    { id: 7, Amount: 320, Name: "Jupiter" },
    { id: 8, Amount: 640, Name: "Topaz" },
    { id: 9, Amount: 1280, Name: "Platinum" },
    { id: 10, Amount: 2560, Name: "Royal" },
    { id: 11, Amount: 5120, Name: "Crown" },
  ];
  const [packages] = useState(AllPackages);
  const [referer, setReferer] = useState("");
  const [packageNo, setPackageNo] = useState(0);

  const [directData, setDirectData] = useState([]);
  const [directStatusId, setdirectStatusId] = useState([]);
  const [directIncome, setdirectIncome] = useState();

  const [levelData, setLevelData] = useState([]);
  const [levelIncome, setlevelIncome] = useState([]);
  const [date, setDate] = useState("");
  const [totalMoney, setTotalMoney] = useState("");
  const [money24hours, setmoney24hours] = useState("");
  const [allUserIncomeLast24hours, setAllUserIncomeLast24hours] = useState("");
  const [userPackageTotal, setUserPackageTotal] = useState("");
  const [UserDirectListLength24hrs, setUserDirectListLength24hrs] =
    useState("");
  const [lastPackage, setLastPackage] = useState("");
  const [team24hrs, setTeam24hrs] = useState("");

  const web3init = new Web3(new Web3.providers.HttpProvider(infuraUrl));

  const [userId, setUserId] = useState("");
  const [totalUser, setTotalUser] = useState("");
  const [shows, setShows] = useState(false);
  const [sponsorUserId, setSponsorUserId] = useState("");
  const [name, setName] = useState("");

  const [walletAddress, setWalletAddress] = useState("");
  const [fullWalletAddress, setFullWalletAddress] = useState("");

  const [qoraData, setQoraData] = useState([]);
  const [QuoraIncome, setQuoraIncome] = useState(0);

  const [royalData, setRoyalData] = useState([]);
  const [royalIncome, setRoyalIncome] = useState([]);
  // RoyalIncome walletAddress

  const [sortedData, setSortedData] = useState([]);
  const [sortedDataIds, setSortedDataIds] = useState([]);
  const [topDirectListing, setTopDirectListing] = useState([]);

  const [totalRoyal, setTotalRoyal] = useState();

  const [directMember, setDirectMember] = useState(0);

  const [myTeam, setMyTeam] = useState(0);

  const [directStatusData, setDirectStatusData] = useState([]);
  const [royaltyStatus, setRoyaltyStatus] = useState("");
  const [sponsorIncome, setSponsorIncome] = useState();
  const [todaysIncome, setTodayIncome] = useState();
  
console.log("todaysIncome",todaysIncome);
  // ====== popup
  const [show, setShow] = useState(false);

  const handleShow = async (address) => {
    setShow(true);
    totalUpdate(address)
  };
  
  var previewAdd = state?.address;

  const totalUpdate = async (address)=>{
    var Trade = new web3init.eth.Contract(ContractAbi, contractAddress);

    const result = await Trade.methods.showUserIncomes(address).call();
  

    const convertVal = result?.amount.map((item) => {
      return item / 10 ** 6;
    });
    

    const total = convertVal.reduce((accumulator, currentNumber) => {
      return accumulator + currentNumber;
    }, 0);
 
    setSponsorIncome(total.toFixed(2));
  }
  const handleClose = () => setShow(false);

  var totalIncomes = levelIncome + directIncome + QuoraIncome + royalIncome;
  // console.log('totalIncome',totalIncomes);

  function getNameByAmount(amount) {
    const packageInfo = AllPackages.find(pack => pack.Amount == amount);

    // Check if a package with the specified amount was found
    if (packageInfo) {
        return packageInfo.Name;
    } else{
      return "No active package";
    }
}
// {console.log("userId",userId)} 

const packageName = getNameByAmount(lastPackage);
console.log("packageName",packageName);



  useEffect(() => {
    checkPackageNo();
  }, []);
  const checkTop = async (userAddr, i) => {
    try {
      var Trade = new web3init.eth.Contract(ContractAbi, contractAddress);
      var userDirectList = await Trade.methods
        .getTopDirectList(userAddr, i)
        .call();
      return userDirectList;
    } catch (error) {
      return checkTop(userAddr, i - 1);
    }
  };
  const checkPackageNo = async () => {
    
    if (previewAdd) {
      var userAddr = previewAdd;
      var Trade = new web3init.eth.Contract(ContractAbi, contractAddress);
      var visibleChars = 8; // You can adjust this as needed
      setFullWalletAddress(userAddr);
      // Create the shortened address
      var shortenedAddress =
        userAddr?.slice(0, visibleChars) +
        "......" +
        userAddr?.slice(-visibleChars);
      setWalletAddress(shortenedAddress);

      Trade.methods
        .investors(userAddr)
        .call((error, result) => {})
        .then((result) => {
          setPackageNo(result.currentPackageIndex);
          setReferer(result?.referer);
          Trade.methods.userIds(result?.referer).call((error, result) => {
            // setSponsorUserId(result);
            // setSponsorUserId(Number(result)+1);
            setSponsorUserId(Number(result) == 0 ?1:result);

          });
          // reffres = result.referer
          setName(result?.name);
        });

      // Trade.methods.userIds(reffres).call((error, result) => {

      //   //  setSponsorUserId(result);
      //  });

      const silver = await Trade.methods.silverPoolCollection().call();
      const gold = await Trade.methods.goldPoolCollection().call();
      const diamond = await Trade.methods.diamondPoolCollection().call();
      const totalRoyal = Number(silver) + Number(gold) + Number(diamond);
      setTotalRoyal(totalRoyal / 10 ** 6);

      // Royalty Status
      let arr = [];

      var AmtLength = await Trade.methods
        .getUserDirectListLength(userAddr)
        .call();

      setDirectMember(AmtLength);
      var totalTeam = await Trade.methods.userPackage(userAddr).call();
      // console.log("totalTeamUser",totalTeam);

      setMyTeam(totalTeam?.Level);

      if (AmtLength > 0) {
        const result = {
          Address: [],
        };

        // for (let i = 0; i < AmtLength; i++) {
        //   var userDirectList = await Trade.methods
        //     .userDirectList(userAddr, i)
        //     .call();
        // for (let i = 0; i < AmtLength; i++) {

        // var userDirectList = await Trade.methods
        //   .getTopDirectList(userAddr, 4)
        //   .call();
        const userDirectList = await checkTop(userAddr, 10);

        result.Address.push(userDirectList);
   
        arr.push(result);
        // }
      }

      let arr2 = [];
      let obj1 = {};

      if (arr?.length > 0) {
        for (let i = 0; i < arr.length; i++) {
          if (arr[i]?.Address && arr[i].Address.length > 0) {
            for (let j = 0; j < arr[i].Address.length; j++) {
              const addresses = arr[i].Address[j]; // This is an array of addresses

              for (let k = 0; k < addresses.length; k++) {
                const address = addresses[k];

                const InvestorData = await Trade.methods
                  .investors(address)
                  .call();

                const obj1 = {
                  walletAddress: address,
                  totalBusinesss: InvestorData.totalBusiness,
                };
                arr2.push(obj1);
              }
            }
          }
        }
      }

      const sortedData = [...arr2].sort((a, b) => {
        return parseInt(a.totalBusinesss) - parseInt(b.totalBusinesss);
      });


      const result = sortedData.reduce((acc, obj) => {
        const existingItem = acc.find(
          (x) => x.walletAddress === obj.walletAddress
        );
        if (existingItem) {
          existingItem.totalBusinesss += Number(obj.totalBusinesss);
        } else {
          acc.push({
            walletAddress: obj.walletAddress,
            totalBusinesss: Number(obj.totalBusinesss),
          });
        }
        return acc;
      }, []);
      // setSortedData(result.reverse());
      getAddressId(result.reverse());
    }
  };

// todays all Income
const getTodaysIncome = async () => {
  try {
    const web3init = new Web3(new Web3.providers.HttpProvider(infuraUrl));
    const userAddr = state?.address;
    const Trade = new web3init.eth.Contract(ContractAbi, contractAddress);
    const QoraIncome = await Trade.methods.showUserIncomes(userAddr).call();
// console.log("QoraIncome",QoraIncome);
    const selectedData = {
      amount: [],
    };

    const today = new Date();
    const todayStart = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    ).getTime() / 1000;
      
    // var currentDate=new Date(Date.now());
      // let d = new Date(Number(QoraIncome[0][4])*1000);
      // console.log('time',d);

    for (let i = QoraIncome.incomeType.length-1; i > 0; --i) {
      if (QoraIncome.time[i]>= todayStart ) {
        selectedData.amount.push(QoraIncome.amount[i]);
      }else{
        break;
      }
    }

    return selectedData.amount;
  } catch (err) {
    return null;
  }
};

  const getQoraDataByIncomeTypePre = async (targetIncomeType)=>
  {
    try {
      const web3init = new Web3(new Web3.providers.HttpProvider(infuraUrl));
      const userAddr = state?.address;
      //  var Trade = new web3init.eth.Contract(ContractAbi, contractAddress);
      // const { ethereum } = window;
      // const accounts = await ethereum.request({ method: "eth_requestAccounts" });
      // const userAddr = accounts[0];
  const Trade = new web3init.eth.Contract(ContractAbi, contractAddress);
  const QoraIncome = await Trade.methods.showUserIncomes(userAddr).call();

  const selectedData = {
    incomeType: [],
    amount: [],
    fromAddr: [],
    time: [],
    level: []
  };

  for (let i = 0; i < QoraIncome.incomeType.length; i++) {
    if (QoraIncome.incomeType[i] === targetIncomeType) {
      selectedData.incomeType.push(QoraIncome.incomeType[i]);
      selectedData.amount.push(QoraIncome.amount[i]);
      selectedData.fromAddr.push(QoraIncome.fromAddr[i]);
      selectedData.time.push(QoraIncome.time[i]);
      selectedData.level.push(QoraIncome.levels[i]);
    }
  }
  return selectedData;
} catch (err) {
  return null;
}
  }

  useEffect(() => {
    const fetchData = async () => {
      const directIncomeData = await getQoraDataByIncomeTypePre("0");
      setDirectData(directIncomeData);

      const levelIncomeData = await getQoraDataByIncomeTypePre("1");
      setLevelData(levelIncomeData);

      const QoraIncomeData = await getQoraDataByIncomeTypePre("2");
      setQoraData(QoraIncomeData);

      const royaltyIncomeData = await getQoraDataByIncomeTypePre("3");
      setRoyalData(royaltyIncomeData);

      const todaysRecord = await getTodaysIncome();
      console.log("res",todaysRecord);
      const sumNum = todaysRecord.reduce((acc, val) => acc + Number(val), 0);
      setTodayIncome(sumNum / 10 ** 6);
    };
    fetchData();
  }, []);

  const userDirectIncome = directData?.amount;

  var numberArray = [];
  userDirectIncome?.forEach((ele) => numberArray.push(+ele));

  let totalDirectIncome = numberArray?.reduce((accumulator, value) => {
    return accumulator + value;
  }, 0);

  useEffect(() => {
    setdirectIncome(totalDirectIncome / 10 ** 6);
  }, [totalDirectIncome]);

  // levelIncome DATA
  const userlevelIncome = levelData?.amount;

  var levelInArry = [];
  userlevelIncome?.forEach((ele) => levelInArry.push(+ele));

  let totolLevelIncome = levelInArry?.reduce((accu, value) => {
    return accu + value;
  }, 0);

  useEffect(() => {
    setlevelIncome(totolLevelIncome / 10 ** 6);
  }, [totolLevelIncome]);

  const userQoura = qoraData?.amount;

  var QuoraArry = [];
  userQoura?.forEach((ele) => QuoraArry.push(+ele));

  let totalQouraIncome = QuoraArry?.reduce((accu, value) => {
    return accu + value;
  }, 0);

  useEffect(() => {
    setQuoraIncome(totalQouraIncome / 10 ** 6);
  }, [totalQouraIncome]);

  // Royal Income DATA
  const userRoyal = royalData?.amount;

  var RoyalArry = [];
  userRoyal?.forEach((ele) => RoyalArry.push(+ele));

  let userRoyalIn = RoyalArry?.reduce((accu, value) => {
    return accu + value;
  }, 0);

  useEffect(() => {
    setRoyalIncome(userRoyalIn / 10 ** 6);
  }, [userRoyalIn]);

  useEffect(() => {
    purchasetimes();
    // parentId()
  }, []);

  const purchasetimes = async () => {
   
    if (previewAdd) {
      var userAddr = previewAdd;
     
      var Trade = new web3init.eth.Contract(ContractAbi, contractAddress);
      Trade.methods.getPackageDetails(userAddr).call((error, result) => {
        var timestamp = result.purchaseTimes[0];

        var date = new Date(timestamp * 1000);

        var day = date.getDate().toString().padStart(2, "0");
        var month = (date.getMonth() + 1).toString().padStart(2, "0");
        var year = date.getFullYear().toString().slice(2);

        var formattedDate = day + "." + month + "." + year;

        setDate(formattedDate);
      });
      Trade.methods.userIds(userAddr).call((error, result) => {
        setUserId(result);
      });
      Trade.methods.totalincomeallusers().call((error, result) => {
        setTotalMoney(result / 10 ** 6);
      });
      Trade.methods.getUsersPurchasedLast24Hours().call((error, result) => {
        setmoney24hours(result);
      });
      Trade.methods.getallusersincomeLast24Hours().call((error, result) => {
        setAllUserIncomeLast24hours(result);
      });
      Trade.methods.totalincomeallusers().call((error, result) => {
        setUserPackageTotal(result / 10 ** 6);
      });

      // getallusersincomeLast24Hours

      Trade.methods.userId().call((error, result) => {
        setTotalUser(result);
      });

      // setUserId
    }
  };
  const UserDirect = async () => {
   
    if (previewAdd) {
      var userAddr = previewAdd;
      
      var Trade = new web3init.eth.Contract(ContractAbi, contractAddress);
      Trade.methods.getPackageDetails(userAddr).call((error, result) => {
        var timestamp = result.purchaseTimes[0];

        var date = new Date(timestamp * 1000);

        var day = date.getDate().toString().padStart(2, "0");
        var month = (date.getMonth() + 1).toString().padStart(2, "0");
        var year = date.getFullYear().toString().slice(2);

        var formattedDate = day + "." + month + "." + year;

        setDate(formattedDate);
      });
      Trade.methods.userIds(userAddr).call((error, result) => {
        setUserId(result);
      });
      Trade.methods.userId().call((error, result) => {
        setTotalUser(result);
      });

      // setUserId
    }
  };

  const copyToClipboard = (value) => {
    if (value) {
      copy(value);
      setShows(true);
      setTimeout(() => {
        setShows(false);
      }, 1500);
    }
  };

  // ====== royalty status ===========================//


//   const RoyaltyDetails = async () => {
//     var userAddr = previewAdd;
      
//     if (userAddr) {
//       var Trade = new web3init.eth.Contract(ContractAbi, contractAddress);

//       const topDirect = await Trade.methods
//         .topFiveDirectBusiness(userAddr)
//         .call();
  
//         let items =[];
//         const amtArr = topDirect.returnArrAddr;
//         const addreArr =   topDirect.returnArr
//           for (let i = 0; i < addreArr.length; i++) {
//             if( Number(addreArr[i])!=0)
//           items.push( <tr key={i}>   
//           <td>{i+1}</td>
//                 <td> { await Trade.methods.userIds(amtArr[i]).call() }</td>
//                 <td> <span className="ml-1">
//                   {/* { Number(Web3.utils.fromWei(addreArr[i],'ether')) } USDT */}
//                   { Number(addreArr[i] / 10**6) } USDT
//                 </span></td>
//           </tr>);
          
//         }
//         setSortedData(items);
//   }
// }


const RoyaltyDetails = async () => {
  var  userAddr = previewAdd;
  if (userAddr) {
    var Trade = new web3init.eth.Contract(ContractAbi, contractAddress);
    const result = await Trade.methods
      .getUserDirectListLength(userAddr)
      .call();
      if (result==0) {
       return setSortedData([]);

      }
      let arr = await Trade.methods
      .getTopDirectList(userAddr,result).call();
      // console.log("asdfsdaf",arr);
     
      let topDirect=[];
  
      for (let i = 0; i < arr.length; i++) {
        let p =Number(await Trade.methods.getLastPackageAmount(arr[i]).call())/10**6;
        if(Number(p!=0))
        if(p>=160){
          let tb =  await Trade.methods.investors(arr[i]).call();
          let wid = await Trade.methods.userIds(arr[i]).call();
          let finalAmt =  (Number(tb?.totalBusiness)/10**6)  -   (Number(tb?.selfBusiness)/10**6);
          // console.log("final",finalAmt);
          topDirect.push(wid);
          topDirect.push (finalAmt);
        }
      }
      let items =[];
      let a=0;
      for (let i = 0; i < Math.min(topDirect.length,10); i+=2) {
      a++;
      if(Number(topDirect[i+1])>0)
      items.push( <tr key={a}>   
      <td>{a}</td>
             <td> {topDirect[i]}</td>
             <td> <span className="ml-1">
               {Number(topDirect[i+1]).toFixed("2")} USDT
             </span></td>
      </tr>);
        
      }
      // console.log("asdf",items);
      // console.log(topDirect);
      setSortedData(items);

}
}

  const RoyaltyStatus = async () => {
   
    if (previewAdd) {
      var userAddr = previewAdd;
      
      const Trade = new web3init.eth.Contract(ContractAbi, contractAddress);
      const result = await Trade.methods
        .getUserDirectListLength(userAddr)
        .call();

        const packages = await Trade.methods
        .investors(userAddr)
        .call();

        const silverPackage  = packages.silverPackage;  
        const goldPackage  = packages.goldPackage;
        const diamondPackage  = packages.diamondPackage;

      let arr = [];
      let status = 0;
      for (let i = 0; i < result; i++) {
        let userDirectList = await Trade.methods
          .userDirectList(userAddr, i)
          .call();
          let slots = await Trade.methods
          .getPackageDetails(userDirectList)
          .call();

        arr.push({
          direct: userDirectList,
          slots: slots.packageAmounts.length,
        });

        if (silverPackage==1 && goldPackage==1 && diamondPackage==1) {
          status = 3;
        }
        else if (silverPackage==1 && goldPackage==1 ) {
          status = 2;
        }
        else  if (silverPackage==1) {
          status = 1;
        }else{
          status = 0;
        }
        
      }
      getWalletAddress(arr);
      setDirectStatusData(arr);
      if (status == 1) {
        setRoyaltyStatus("Silver");
      } else if (status == 2) {
        setRoyaltyStatus("Gold");
      } else if (status == 3) {
        setRoyaltyStatus("Diamond");
      } else {
        setRoyaltyStatus("");
      }
    }
  };

  const getWalletAddress = async (tokens) => {
    const { ethereum } = window;
    if (previewAdd && tokens.length > 0) {
    const web3init = new Web3(new Web3.providers.HttpProvider(infuraUrl));
      var Trade = new web3init.eth.Contract(ContractAbi, contractAddress);
      const values = await Promise.all(tokens.map(async (token) => await Trade.methods.userIds(token.direct).call()));
      setdirectStatusId(values);
    }
  }
  const getTopDirectss = async () => {
   
    if (previewAdd) {
      var userAddr = previewAdd;
     
      const Trade = new web3init.eth.Contract(ContractAbi, contractAddress);

      // for(let i =1;i<5;i++){
      if (directMember.length >= 4) {
        const result = await Trade?.methods
          .getTopDirectList(userAddr, 4)
          .call();
        setTopDirectListing(result);
      }
      // }

      const resultss = await Trade?.methods
        .getUserDirectListLength24hrs(userAddr)
        .call();

      setUserDirectListLength24hrs(resultss);

      const teamss = await Trade?.methods.userPackage(userAddr).call();
      setTeam24hrs(teamss?.hrslevel);
      const lastPackagess = await Trade?.methods
        .getLastPackageAmount(userAddr)
        .call();

      setLastPackage(lastPackagess / 10 ** 6);
    }
  };

  const getAddressId = async (tokens) => {
    if (previewAdd && tokens.length > 0) {
      var Trade = new web3init.eth.Contract(ContractAbi, contractAddress);
      const values = await Promise.all(tokens.map(async (token) => await Trade.methods.userIds(token.walletAddress).call()));
      setSortedDataIds(values);
    }
  }

  useEffect(() => {
    RoyaltyDetails();
    RoyaltyStatus();
    getTopDirectss();
  }, []);

  return (
    <>
      <div>
        <Loader />

        <PreTopbar />

        <div className="dash_bar_man">
          <PreSidebar />

          <div className="right_bar">
            <div className="row row5">
              <div className="col-md-7 mb-4 mb-md-55">
                <div className="dash_box1">
                  <div className="d-flex align-items-center">
                    <div className="img_box">
                      <img
                        alt=""
                        className="max-h-full"
                        src="/assets/img/user.png"
                      />
                    </div>
                    <div className="invited_box">
                      <div>
                        <span>
                          <h5>
                            ID: {userId==0?1:userId} <span className="ml-2">{name}</span>
                          </h5>
                        </span>
                      </div>
                      {shows ? (
                        <span style={{ color: "green", marginRight: "5px" }}>
                          This walletAddress Copied {fullWalletAddress}
                        </span>
                      ) : (
                        <span
                          title="copy"
                          onClick={() => copyToClipboard(fullWalletAddress)}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          {" "}
                          {/* <FiCopy
                            title="copy"
                            style={{
                              marginRight: "8px",
                              cursor: "pointer",
                            }}
                          /> */}
                          {/* <i className="fa fa-copy" /> */}
                          <h5>
                            {walletAddress} <i className="fa fa-copy" />
                          </h5>
                        </span>
                      )}
                      <div className="invited">
                        <span>{date.includes(NaN) ? "" : date}</span>
                        {/* <span
                          style={{ cursor: "pointer" }}
                          onClick={() => handleShow(referer)}>
                          invited by ID {sponsorUserId}
                        </span> */}
                        {userId!=1? 
                        <span style={{ cursor: "pointer" }} 
                        onClick={() => handleShow(referer)}>
                        invited by ID {sponsorUserId}
                      </span>
                         :
                        <span style={{ cursor: "pointer" }} >
                          invited by ID {sponsorUserId}
                        </span>
                         }

                        
                      </div>
                      <div className="reff1">
					   <PrePersonlLink packageNo={packageNo} />
                      </div>

                    </div>
                  </div>
                </div>
                {/* <p className="mt-3 wbwb">
                  <b>Sponser Address :</b> {referer != "0x0000000000000000000000000000000000000000" ? referer:""}{" "}
                </p> */}

          <div className="reff2 mb-3">
					   <PrePersonlLink packageNo={packageNo} />
                      </div>
              </div>

              
              <div className="col-md-4 mb-4 mb-md-55">
                <div className="dash_box h100 text-center">
                  <h6 className="d_hadding mt-md-4">Total Incomes </h6>
                  <div className="mb-2  ">
                    <h4 className="m-0 fw600">
                      {Number(totalIncomes)?.toFixed(4)}
                    </h4>
                  </div>

                  {todaysIncome > 0 ? (
                <div className="dash_box_bottom active d-dlex">
                  <i className="fa fa-arrow-up mr-2" /> {todaysIncome ? todaysIncome.toFixed(2) : "0"}
                  <i className="fa fa-spinner ml-auto" />
                </div>
              ) : (
                <div className="dash_box_bottom  d-dlex">
                  <i className="fa fa-arrow-up mr-2" /> {todaysIncome ? todaysIncome.toFixed(2) : "0"}
                  <i className="fa fa-spinner ml-auto" />
                </div>
              )}

                </div>
              </div>

              <div className="col-md-4 mb-4 mb-md-55">
                <div className="dash_box h100 mh140">
                  <h6 className="d_hadding">Directs Partner </h6>

                  <h4 className="fw600">{directMember}</h4>

                  {UserDirectListLength24hrs>0 ?(
                    <div className="dash_box_bottom active d-dlex">
                    <i className="fa fa-arrow-up mr-2" />
                    {UserDirectListLength24hrs}
                    <i className="fa fa-spinner ml-auto" />
                  </div>
                  ):( <div className="dash_box_bottom d-dlex">
                    <i className="fa fa-arrow-up mr-2" />
                    {UserDirectListLength24hrs}
                    <i className="fa fa-spinner ml-auto" />
                  </div> ) }

                </div>
              </div>

              <div className="col-md-4 mb-4 mb-md-55">
                <div className="dash_box h100 mh140">
                  <h6 className="d_hadding">Team </h6>
                  <h4 className="fw600">{myTeam}</h4>
                  {/* <div className="dash_box_bottom active d-dlex ">
                    <i className="fa fa-arrow-up mr-2" /> {team24hrs}
                    <i className="fa fa-spinner ml-auto" />
                  </div> */}
                </div>
              </div>

              <div className="col-md-4 mb-4 mb-md-55">
                <div className="dash_box h100 mh140">
                  <h6 className="d_hadding">Royalty status </h6>
                  {/* <h4 className="fw600">{totalRoyal}</h4> */}
                  <h4 className="fw600">{royaltyStatus}</h4>

                  {/* <div className="dash_box_bottom d-dlex ">
                        <i className="fa fa-arrow-up mr-2" /> 0.00{" "}
                        <i className="fa fa-spinner ml-auto" />
                      </div> */}
                </div>
              </div>

            </div>

            <div className="row">
              <div className="col-md-6 mb-4 mb-md-55">
                <div className="dash_box h100 ">
                  <div className="d-flex mb-4">
                    <h5 className="m-0 fw600">Slot </h5>

                    <h5 className="m-0 fw600 ml-auto">{userId==0?'':lastPackage} {userId==0?'All Package Activated ':packageName}</h5>
                  </div>
                  <div className="row align-items-end">
                    <div className="col-md-8 ">
                      <div className="d-flex boxs flex-wrap">
                        {packages &&
                          packages.map((item) => {
                            return (
                              <div >
                                {item.id >= Number(packageNo) + 1 ? (
                                  <div className="d-flex boxs_in">
                                    {item?.Amount}
                                    <img
                                      src="/assets/img/usdt.png"
                                      alt="header-Logo"
                                      className="logo5"
                                    ></img>
                                  </div>
                                ) : (
                                  <div className="d-flex boxs_in active">
                                    {item?.Amount}
                                    <img
                                      src="/assets/img/usdt.png"
                                      alt="header-Logo"
                                      className="logo5"
                                    ></img>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    <div className="col-md-4 text-right">
                      <Link to="/preview-package-report" className="btn btn_man">
                        <span>
                          Preview <i className="fa fa-arrow-circle-right" />
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-4 mb-md-55">
                <div className="dash_box h100 ">
                  <div className="d-flex mb-4">
                    <h5 className="m-0 fw600">11Qore Income</h5>
                    <h5 className="m-0 fw600 ml-auto">
                      {Number(QuoraIncome)?.toFixed(4)} USDT
                    </h5>
                  </div>
                  <div className="row align-items-end">
                    <div className="col-md-12 ">
                      <div className="d-flex boxs flex-wrap">
                        <div className="d-flex boxs_in active"> </div>
                        <div className="d-flex boxs_in active"> </div>
                        <div className="d-flex boxs_in active"> </div>
                        <div className="d-flex boxs_in active"> </div>
                        <div className="d-flex boxs_in"> </div>
                        <div className="d-flex boxs_in"> </div>
                        <div className="d-flex boxs_in"> </div>
                        <div className="d-flex boxs_in"> </div>
                        <div className="d-flex boxs_in"> </div>
                        <div className="d-flex boxs_in"> </div>
                        <div className="d-flex boxs_in"> </div>
                        <div className="d-flex boxs_in"> </div>
                      </div>
                    </div>
                    <div className="col-md-12 text-right">
                      <Link to="/preview-quore" className="btn btn_man">
                        <span> Preview <i className="fa fa-arrow-circle-right" />
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-4 mb-md-55">
                <div className="dash_box mb-4 ">
                  <div className="d-flex mb-4">
                    <h5 className="m-0 fw600">Generation Level Income</h5>
                    <h5 className="m-0 fw600 ml-auto">
                      {Number(levelIncome)?.toFixed(4)} USDT
                    </h5>
                  </div>
                  <div className="row align-items-end">
                    <div className="col-md-5 ">
                      <div className="d-flex boxs flex-wrap">
                        <div className="d-flex boxs_in active"> </div>
                        <div className="d-flex boxs_in active"> </div>
                        <div className="d-flex boxs_in active"> </div>
                        <div className="d-flex boxs_in active"> </div>
                        <div className="d-flex boxs_in"> </div>
                        <div className="d-flex boxs_in"> </div>
                        <div className="d-flex boxs_in"> </div>
                        <div className="d-flex boxs_in"> </div>
                        <div className="d-flex boxs_in"> </div>
                        <div className="d-flex boxs_in"> </div>
                        <div className="d-flex boxs_in"> </div>
                        <div className="d-flex boxs_in"> </div>
                      </div>
                    </div>
                    <div className="col-md-7 text-right">
                      <Link to="/preview-level" className="btn btn_man">
                        <span>
                          Preview <i className="fa fa-arrow-circle-right" />
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="dash_box">
                  
                  <div className="d-flex mb-4">
                    <h6 className="m-0 fw600">Direct User ID</h6>
                    <h6 className="m-0 fw600 ml-auto">Slot</h6>
                  </div>
                  <div className="scrool_d">
                    {directStatusData.map((list,index) => {
                      return (
                        <div className="d-flex mb-4">
                          <div className="m-0 direct_address">{directStatusId[index]}</div>
                          {/* <div className="m-0 direct_address">{list.direct}</div> */}
                          <div className="m-0 ml-auto">
                            {list.slots} Slot
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="col-md-6 mb-4 mb-md-55">
                <div className="dash_box h100 ">
                  <div className="d-flex mb-4">
                    <h5 className="m-0 fw600">Royalty Status</h5>
                    {/* <h4 className="m-0 fw600 ml-auto">{royalIncome} USDT</h4> */}
                  </div>
                  <div className="table-responsive">
                   
                      <table className="table royalty_status">
                      <tr>
                              <th>Sn.</th>
                              <th>User ID</th>
                              <th>Business</th>
                              </tr>
                              <tbody>
                                {sortedData}
                              </tbody>
                      </table>
                    
                  </div>
                </div>
              </div>
            </div>

            <h4 className="fw600 mb-3">Platform Recent Activity</h4>

            <div className="row ">
              <PreTransactionList user="refresh" />
              <div className="col-md-4">
                <div className="dash_box  mb-4">
                  <h6 className="d_hadding mb-4">Members total </h6>
                  <h4 className="fw600">{totalUser ? totalUser : "0"}</h4>
                  <div className="ud_green">
                    <i className="fa fa-arrow-up mr-2" />
                    {money24hours ? money24hours : "0"}
                  </div>
                </div>
                <div className="dash_box  mb-4">
                  <h6 className="d_hadding mb-4">
                    Member total amount received{" "}
                  </h6>
                  <h4 className="fw600">
                    {userPackageTotal ? userPackageTotal.toFixed(2) : "0"}
                  </h4>
                  {/* <div className="ud_green">+ 40 739.1 USDT</div> */}
                  <hr />
                  {/* <h4 className="fw600">{userPackageTotal?userPackageTotal:"0"} USDT</h4> */}
                  {/* <div className="ud_green">+ {allUserIncomeLast24hours?allUserIncomeLast24hours:"0"} USDT</div> */}
                </div>
                <div className="dash_box  ">
                  <h6 className="d_hadding mb-3">Total Royalty Income </h6>
                  <h4 className="fw600">
                    {totalRoyal ? totalRoyal.toFixed(2) : "0"} USDT
                  </h4>
                  {/* <div className="ud_green">+ 40 739.1 USDT</div> */}
                </div>

                {/* <div className="dash_box mt-4  ">
                  <h6 className="d_hadding mb-3">Highlight Income </h6>
                  <h4 className="fw600">
                    {todaysIncome ? todaysIncome.toFixed(2) : "0"} USDT
                  </h4>
                </div> */}
                  {/* <div className="ud_green">+ 40 739.1 USDT</div> */}

              </div>
            </div>
            {/* <Button variant="primary" onClick={handleShow}>
              Open Popup
            </Button> */}

            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Sponsor</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-6 mb-3"> ID : </div>
                  <div className="col-6 mb-3"> {sponsorUserId} </div>

                  <div className="col-6 mb-3">Total Income : </div>
                  <div className="col-6 mb-3">{sponsorIncome} USDT</div>
                </div>
              </Modal.Body>
            </Modal>
            <CopyRight />
          </div>
        </div>
      </div>
    </>
  );
};
