import React from "react";
import { Link } from "react-router-dom";

export const Navbar = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
          <Link to="/" className="navbar-brand">
            <img src="/assets/img/logo.svg" alt="header-Logo" className="logo" />
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarText">

            <span className="icon-bar">
              <i className="fa fa-bars fa-2x" />
            </span>
          </button>

          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav m-auto ">
              <li className="nav-item">
                <Link to='/'className="nav-link active"
                  data-scroll-nav={1}>
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#contact" data-scroll-nav={3}>
                  About Us
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#contact" data-scroll-nav={4}>
                  Roadmap
                </a>
              </li>
              {/* <li class="nav-item drop dropdown">
               <a class="nav-link " href="#"  data-toggle="dropdown">More <i class="fa fa-angle-double-down" ></i>
               </a>
               <div class="dropdown-menu ">
               <div class="manu_box">
               <a class="mh1" href="tier-system.html">
                   <i class="fa fa-star-o" ></i>   Tier System  </a>
               </div>
               <div class="manu_box">
               <a class="mh1" href="calendar.html">
                   <i class="fa fa-calendar-check-o" ></i>  IDOs  Calendar 
               </a>
               </div>
               </div>
               </li> */}
            </ul>
          </div>
          <div className="btn_b">
          <Link to="login" disabled className="btn btn_man ml-auto"> <span>Login/Register</span> </Link>
          {/* <div  disabled className="btn btn_man ml-auto"> <span>Login/Register</span> </div> */}
          
          </div>
        </div>
      </nav>
    </>
  );
};
