import{Home} from './pages/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Login } from './pages/Login';
import { Dashboard } from './pages/dashboard/Dashboard';
import { Package } from './pages/dashboard/Package';
import { PackgeReport } from './pages/dashboard/PackgeReport';
import { DirectIncomeReport } from './pages/dashboard/DirectIncomeReport';
import { LevelIncome } from './pages/dashboard/LevelIncome';
import { QoraReport } from './pages/dashboard/QoraReport';
import { RoyaltyReport } from './pages/dashboard/RoyaltyReport';
import { Tree } from './pages/dashboard/Tree';
import { Parteners } from './pages/dashboard/Parteners';
import { Preview } from './pages/dashboard/Preview';
import { MyContextProvider } from './context'
import { PreQoraReport } from './pages/preview/PreQoraReport';
import { PreLevelIncome } from './pages/preview/PreLevelIncome';
import { PreDirectIncome } from './pages/preview/PreDirectIncome';
import { PrePackgeReport } from './pages/preview/PrePackgeReport';
import Calculator from './pages/dashboard/Calculator';
import PreCalculator from './pages/preview/PreCalculator';
import { PreTree } from './pages/preview/PreTree';

function App() {
  
  return (
    <>
      <MyContextProvider>
      <BrowserRouter>
      <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/login' element={<Login/>} />
          <Route path='/login/:id' element={<Login />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/package' element={<Package />} />
          <Route path='/package-report' element={<PackgeReport />} />
          <Route path='/direct-report' element={<DirectIncomeReport />} />
          <Route path='/levelIncome-report' element={<LevelIncome />} />
          <Route path='/QuoreIncome-report' element={<QoraReport />} />
          <Route path='/royaltyIncome-report' element={<RoyaltyReport />} />
          <Route path='/partners' element={<Parteners />} />
          <Route path='/preview' element={<Preview />} />
          <Route path='/preview-quore' element={<PreQoraReport />} />
          <Route path='/preview-level' element={<PreLevelIncome />} />
          <Route path='/preview-direct' element={<PreDirectIncome />} />
          <Route path='/preview-package-report' element={<PrePackgeReport />} />
          <Route path='/calculator' element={<Calculator />}/>
          <Route path='/preview-calculator' element={<PreCalculator />}/>
		      <Route path='/tree/:id' element={<Tree />} />
          <Route path='/preview-tree/:id' element={<PreTree />}/>

      </Routes>
      </BrowserRouter>
      </MyContextProvider>
    </>
  );

}

export default App;
