import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import Web3 from "web3";
import { Link } from "react-router-dom";
import { ethers } from "ethers";
import { contractAddress, ContractAbi, infuraUrl } from "../dashboard/Constant";

export const PreTransactionList = (props) => {
  useEffect(() => {
    fetchTransactions();

    fetchUserIds(transactions);
    // .then(() => {
    //   setData(arr);
    //   // console.log(arr, "000231251");
    // })
    // .catch((error) => {
    //   console.error(
    //     "An error occurred during the fetchUserIds operation:",
    //     error
    //   );
    // });
  }, [props]);
  const [transactions, setTransactions] = useState([]);

  // console.log(transactions, "transactions");
  const [userId, setUserId] = useState([]);
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  
  // const infuraUrl = 'https://bsc-dataseed.binance.org/';
  const web3init = new Web3(new Web3.providers.HttpProvider(infuraUrl));

  // const API_KEY = "JIAXZM1Q4I3YHY6924JFRE1886XCXN7V86";
  const API_KEY = "RDFFZ4ESXBK2K2XE14HH1G1K1JESAJ9TEU";

  const fetchTransactions = async () => {
    // const apiUrl = `https://api.bscscan.com/api?module=account&action=txlist&address=${contractAddress}&startblock=0&endblock=99999999999999&page=1&offset=6&sort=desc&apikey=JIAXZM1Q4I3YHY6924JFRE1886XCXN7V86`;

    const apiUrl = `https://api.polygonscan.com/api?module=account&action=txlist&address=${contractAddress}&startblock=0&endblock=99999999999999&page=1&offset=5&sort=desc&apikey=${API_KEY}`

    try {
      const response = await fetch(apiUrl);
      if (response.ok) {
        const data = await response.json();

        setTransactions(data.result);
      } else {
        console.error("API request failed");
      }
    } catch (error) {
      console.error("API request error", error);
    }
  };
  // useEffect(() => {

  // }, []);

  // async function fetchUserIds(transactions) {
  //   // const transaction = transactions.slice(-1)[0];
  //   console.log(transactions, "transaction111");
  //   const { ethereum } = window;

  //   if (!ethereum) {
  //     console.error("Ethereum provider is not available.");
  //     return;
  //   }

  //   try {
  //     const accounts = await ethereum.request({
  //       method: "eth_requestAccounts",
  //     });

  //     // const unique = [...new Set(transactions.map((obj) => obj.from))];
  //     let transactionFrom = new Set();
  //     let transaction = transactions.filter((bird) => {
  //       if (!transactionFrom.has(bird.from)) {
  //         transactionFrom.add(bird.from);
  //         return true;
  //       }
  //       return false;
  //     });

  //     for (let i = 0; i < transaction.length; i++) {
  //       const transactionn = transaction[i];
  //       console.log(transaction[i], "transactionn");
  //       // console.log(transaction,"transaction0121")

  //       let { from, timeStamp } = transactionn;

  //       if (accounts) {
  //         const web3 = new Web3(window.ethereum);
  //         const Trade = new web3.eth.Contract(ContractAbi, contractAddress);

  //         const userId = await Trade.methods.userIds(from).call();
  //         let getAmt = await Trade.methods.getPackageDetails(from).call();
  //         getAmt = getAmt[0].slice(-1).pop();
  //         getAmt = getAmt / 10 ** 6;

  //         const providedTimestamp = timeStamp * 1000;

  //         // Create a Date object from the provided timestamp
  //         const providedDate = new Date(providedTimestamp);

  //         // Get the current date and time in IST
  //         const istOffset = 5.5 * 60 * 60 * 1000; // IST is UTC+5.5
  //         const currentISTTime = new Date(Date.now() + istOffset);

  //         // Calculate the time difference in milliseconds
  //         const timeDifferenceInMilliseconds = currentISTTime - providedDate;

  //         // Calculate hours and minutes from the time difference
  //         const hours = Math.floor(
  //           timeDifferenceInMilliseconds / (60 * 60 * 1000)
  //         );
  //         const minutes = Math.floor(
  //           (timeDifferenceInMilliseconds % (60 * 60 * 1000)) / (60 * 1000)
  //         );

  //         console.log(`Time difference: ${hours} hours and ${minutes} minutes`);

  //         // const date = new Date(timeStamp * 1000);

  //         // const hours = date.getHours();
  //         // const minutes = date.getMinutes();
  //         arr.push({ userId, getAmt, hours, minutes });

  //         console.log(arr, "arr");
  //       }
  //     }
  //   } catch (error) {
  //     console.error("An error occurred while fetching user IDs:", error);
  //   }
  // }
  async function fetchUserIds(transactions) {
    // const transaction = transactions.slice(-1)[0];
    let arr = [];
    const { ethereum } = window;

    if (!ethereum) {
      return;
    }

    try {
      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });

      // const unique = [...new Set(transactions.map((obj) => obj.from))];
      // let transactionFrom = new Set();
      // let transaction = transactions.filter((bird) => {
      //   if (!transactionFrom.has(bird.from)) {
      //     transactionFrom.add(bird.from);
      //     return true;
      //   }
      //   return false;
      // });
      const datas = transactions.filter(
        (item) => item.functionName == "buyPackage(uint256 packageId)"
      );

      const uniqueAgesMap = new Map();

      datas.forEach((person) => {
        uniqueAgesMap.set(person.from, person);
      });

      const data = Array.from(uniqueAgesMap.values());

      for (let i = 0; i < data.length; i++) {
        let { from, timeStamp } = data[i];


        if (accounts) {
          const Trade = new web3init.eth.Contract(ContractAbi, contractAddress);

          const userId = await Trade.methods.userIds(from).call();

          let getAmt = await Trade.methods.getPackageDetails(from).call();

          const getAmts = getAmt[0];

          const getAmtArray = Array.from(getAmts);
          let reversedGetAmtArray = getAmtArray.reverse();

          const providedTimestamp = timeStamp * 1000;

          const providedDate = new Date(providedTimestamp);

          const istOffset = 5.5 * 60 * 60 * 1000;
          const currentISTTime = new Date(Date.now());
          // const currentISTTime = Date.now() + istOffset;

          const timeDifferenceInMilliseconds = currentISTTime - providedDate;

          const hours = Math.floor(
            timeDifferenceInMilliseconds / (60 * 60 * 1000)
          );
          const minutes = Math.floor(
            (timeDifferenceInMilliseconds % (60 * 60 * 1000)) / (60 * 1000)
          );

          for (let j = 0; j < reversedGetAmtArray.length; j++) {
            // for (let k = 0; k < reversedGetAmtArray[j].length; k++) {
            const reversedGetAmtArrays = reversedGetAmtArray[j] / 10 ** 6;
            if (reversedGetAmtArrays) {
              arr.push({
                userId,
                getAmt: reversedGetAmtArrays,
                hours,
                minutes,
              });
              setData(arr);
            }

            // }
          }
        }
      }
    } catch (error) {
      console.error("An error occurred while fetching user IDs:", error);
    }
  }

  return (
    <>
      {" "}
      <div className="col-md-8">
        <div className="dash_box h100 ">
          {data.map((item, i) => {
            return (
              <div className="list_box active">
                <div className="list_icon_box  mr-2 mr-md-3">
                  {" "}
                  <i className="fa fa-credit-card" />{" "}
                </div>
                <Link>ID {item.userId}</Link>
                <span>+ {item.getAmt} USDT</span>
                <span className="ml-auto">
                  <i className="fa fa-external-link mr-2" />
                  {item?.hours == 0 ? "" : item?.hours + "hours and "}
                  {item?.minutes} minutes
                </span>
              </div>
            );
          })}

          {/* <div className="list_box active">
            <div className="list_icon_box  mr-2 mr-md-3">
              {" "}
              <i className="fa fa-credit-card" />{" "}
            </div>
            <Link to="#" className="link_btn">
              ID 1493827
            </Link>
            <span>+ 5 USDT in x4</span>
            <span className="ml-auto">
              <i className="fa fa-external-link mr-2" /> 7 minutes
            </span>
          </div>
          <div className="list_box active">
            <div className="list_icon_box  mr-2 mr-md-3">
              {" "}
              <i className="fa fa-credit-card" />{" "}
            </div>
            <Link to="#" className="link_btn" href="#">
              ID 1493827
            </Link>
            <span>+ 5 USDT in x4</span>
            <span className="ml-auto">
              <i className="fa fa-external-link mr-2" /> 7 minutes
            </span>
          </div>
          <div className="list_box active">
            <div className="list_icon_box  mr-2 mr-md-3">
              {" "}
              <i className="fa fa-credit-card" />{" "}
            </div>
            <Link to="#" className="link_btn">
              ID 1493827
            </Link>
            <span>+ 5 USDT in x4</span>
            <span className="ml-auto">
              <i className="fa fa-external-link mr-2" /> 7 minutes
            </span>
          </div>
          <div className="list_box active">
            <div className="list_icon_box  mr-2 mr-md-3">
              {" "}
              <i className="fa fa-credit-card" />{" "}
            </div>
            <Link to="#" className="link_btn">
              ID 1493827
            </Link>
            <span>+ 5 USDT in x4</span>
            <span className="ml-auto">
              <i className="fa fa-external-link mr-2" /> 7 minutes
            </span>
          </div> */}
        </div>
      </div>
    </>
  );
};
