import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useMyContext } from "../../context";
export const PreSidebar = () => {
  const { state } = useMyContext();
  const [activeLink, setActiveLink] = useState(() => {
    return localStorage.getItem("active") || "dashboard";
  });
  const navigate = useNavigate();

  const handleLinkClick = (type) => {
    setActiveLink(type);
    localStorage.setItem("active", type);
  };

  useEffect(() => {
    const storageListener = (e) => {
      if (e.key === "active") {
        setActiveLink(e.newValue);
      }
    };

    window.addEventListener("storage", storageListener);

    return () => {
      window.removeEventListener("storage", storageListener);
    };
  }, []);

  const [show, setShow] = useState(false);

  const handleShow = async (address) => {
    setShow(true);
  };
  const handleClose = () => setShow(false);

  return (
    <>
      <div className="left_bar">
        <nav className="navbar2 ">
          <div className>
            <div className="collapse navbar-collapse" id="navbarText">
              <ul className="navbar-nav m-auto ">
                <li className="nav-item">
                  <Link
                    to="/preview"
                    className={`nav1 ${
                      activeLink === "preview" ? "active" : ""
                    }`}
                    onClick={() => handleLinkClick("preview")}
                  >
                    <i className="fa fa-home mr-2" /> Dashboard
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    to="/preview-direct"
                    className={`nav1 ${
                      activeLink === "preview-direct" ? "active" : ""
                    }`}
                    onClick={() => handleLinkClick("preview-direct")}
                  >
                    <i className="fa fa-trophy mr-2" /> Direct Referral Reports
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    to="/preview-tree/user"
                    className={`nav1 ${
                      activeLink === "preview-tree/user" ? "active" : ""
                    }`}
                    onClick={() => handleLinkClick("preview-tree/user")}
                  >
                    <i className="fa fa-trophy mr-2" /> Tree
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    to="/preview-calculator"
                    className={`nav1 ${
                      activeLink === "preview-calculator" ? "active" : ""
                    }`}
                    onClick={() => handleLinkClick("preview-calculator")}
                  >
                    <i className="fa fa-trophy mr-2" />Calculator
                  </Link>
                </li>


                {/* <div className="manu_box">
                      <Link to='/calculator' className="mh1">
                        <i className="fa fa-calculator-check-o mr-2" />
                        Calculator
                      </Link>
                    </div> */}

              </ul>
            </div>
            
          </div>
        </nav>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Promo &amp; PDFs</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center">
              {/* /assets/pdf/pdf10_1.pdf */}
              <a
                className="btn btn-success mr-2"
                href="/assets/pdf/pdf10_1.pdf"
                download>
                Download Pdf
              </a>
              <Link
                className="btn btn-success"
                to="https://www.youtube.com/@LifeEnjoy.io23"
                target="_blank"
              >
                Video Link
              </Link>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};
