import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
// import { WEBURL, baseUrl } from "./Constant";
import { useMyContext } from "../../context";

const PersonlLink = (props) => {

  const { state } = useMyContext();
  const [isCopied, setIsCopied] = useState(false);
  const [userAddress, setUserAddress] = useState("");
  const previewAdd = state?.address;
  useEffect(() => {
    fetchUserAddress();
  }, []);

  const fetchUserAddress = async () => {
    const { ethereum } = window;
    if (previewAdd) {
      // const myReferral = WEBURL+`login/#register > div:nth-child(1) > input/{$userAddr}`+userAddr;
      // setUserAddress(myReferral);
      setUserAddress(previewAdd);
    }
  };

  const handleCopyClick = () => {
    const textArea = document.createElement("textarea");
    textArea.value = "https://lifeenjoy.io/login/"+userAddress;
    // textArea.value = baseUrl+userAddress;
    document.body.appendChild(textArea);
    textArea.select();
    try {
      // Execute the copy command
      document.execCommand("copy");
      setIsCopied(true);
      toast.success("successfully copy.");
    } catch (err) {
      toast.error("Unable to copy text: ", err);
    }
    // Remove the textarea from the DOM
    document.body.removeChild(textArea);
  };
  return (
    <>
      <h6 className="d_hadding mb-0 mt-3">Referral link </h6>
          {Number(props.packageNo)>0 ? (<div className="d-flex align-items-center">
            <p className="color1 wbwb mr-3 mb-0">https://lifenjoy...{userAddress} </p>
            <button className="btn btn_man btn_man_small ml-auto"
              onClick={handleCopyClick}>
            
              <span> Copy </span>{" "}
            </button>
          </div>) :(<div className="d-flex align-items-center">
            <h6 className="color1 wbwb">Referral link generate when package buy</h6>
          </div>) }
    </>
  );
};

export default PersonlLink;
