import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  contractAddress,
  ContractAbi,
  chainIdVal,
  chainIdMsg,
} from "./dashboard/Constant";
import Web3 from "web3";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { useMyContext } from "../context";


export const Login = ({login}) => {
  const { id } = useParams();
  const { state, dispatch } = useMyContext();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const [sponserId, setSponserId] = useState("0x42C2630E02a10F07F2B487B7E85E24a56d159b6c");
  // const [sponserId, setSponserId] = useState("0x8c0AC261782146b9F954d2eB3ee476ae8653dBAD");
  const { ethereum } = window;
  useEffect(() => {
    initialize();
    if (typeof id !== "undefined") {
      setSponserId(id)
    }
  }, []);

  const handleSponserIdChange = (e) => {
    setSponserId(e.target.value);
  };
  const handleChange = (e)=>{
    const {name,value}= e.target
    if(name==="name"){
      setName(value)
      // if(value==""){
      //   setName(value)
      //   setNameErr("This field is required")
      // }

    }

  }

  const initialize = async (e) => {
    try {
      var accounts = await ethereum?.request({ method: "eth_requestAccounts" });
      if (accounts) {
        var userAddr = accounts[0];
        window.ethereum.on("accountsChanged", function (accounts) {
          window.location.reload();
        });
        setUserAddress(userAddr);
        const chainId = await ethereum.request({ method: "eth_chainId" });
        if (chainId != chainIdVal) {
          toast.error(chainIdMsg);
          return false;
        }
      } else {
        toast.error("Please Install Metamask or connect to Metamask!");
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const register = async (e) => {
    try {
      if(!name){
        
        toast.error("Name is required");
        return
      }
      e.target.disabled = true;
      var accounts = await ethereum?.request({ method: "eth_requestAccounts" });
      if (accounts) {
        var userAddr = accounts[0];
        var web3 = new Web3(window.ethereum);
        const Trade = new web3.eth.Contract(ContractAbi, contractAddress);
        var check = web3.utils.isAddress(sponserId);
        if (check === true) {
          Trade.methods.investors(userAddr).call(async (error, result) => {
            if (Boolean(result.registered === true)) {
              toast.error("Already Registered.");
              e.target.disabled = false;
            } else {
              Trade.methods.investors(sponserId).call(async (error, result) => {
                if (Boolean(result.registered === true)) {
                  await Trade.methods
                    .register(sponserId,name)
                    .send({ from: userAddr }, (error, result) => {})
                    .then((result) => {
                      toast.success("Successfully Registered.");
                      console.log("clear-data");
                      setTimeout(function () {
                        navigate("/dashboard");
                      }, 2000);
                    })
                    .catch((error) => {
                      toast.error(error);
                      e.target.disabled = false;
                    });
                } else {
                  toast.error("Invaild Sponser Address.");
                  e.target.disabled = false;
                }
              });
            }
          });
        } else {
          toast.error("Invaild Wallet Address.");
          e.target.disabled = false;
        }
      } else {
        toast.error("Please Install Metamask or connect to Metamask!.");
        e.target.disabled = false;
      }
    } catch (error) {
      toast.error(error);
      e.target.disabled = false;
    }
  };

  const UserLogin = async (e) => {
    try {
      e.target.disabled = true;
      var accounts = await ethereum?.request({ method: "eth_requestAccounts" });
      if (accounts) {
        var userAddr = accounts[0];
        var web3 = new Web3(window.ethereum);
        const Trade = new web3.eth.Contract(ContractAbi, contractAddress);
        Trade.methods
          .investors(userAddr)
          .call((error, result) => {})
          .then((result) => {
            if (Boolean(result.registered === true)) {
              toast.success("Successfully Login.");
              dispatch({ type: 'REMOVE_ADDRESS'});
              setTimeout(() => {
                navigate("/dashboard");
              }, 2000);
            } else {
              toast.error("Wallet Address in not registered.");
              e.target.disabled = false;
            }
          });
      } else {
        toast.error("Please Install Metamask or connect to Metamask!.");
        e.target.disabled = false;
      }
    } catch (error) {
      toast.error(error);
      e.target.disabled = false;
    }
  };

  return (
    <>
      <video
        className="video-wrap"
        playsInline="playsinline"
        autoPlay="autoplay"
        muted="muted"
        loop="loop">
        <source src="assets/video.mp4" type="video/mp4" />
      </video>

      <div className="login_box">
        <div className="container m-auto">
          <div className="row">
            <div className="col-md-5 m-auto">
              <div className="blar_box p-4 p-md-5 text-center si login_box_in">
                <Link to="/">
                  {" "}
                  <img
                    src="/assets/img/logo.svg"
                    alt="header-Logo"
                    className="logo mb-4"
                  />
                </Link>
                <ul className="nav tab_login mb-4">
                  <li className="nav-item">
                    <a className={`nav-link ${login===true?'active':''}`} data-toggle="tab" href="#Login">
                      Login
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className={`nav-link ${login!==true?'active':''}`}
                      data-toggle="tab"
                      href="#register">
                      Register
                    </a>
                  </li>
                </ul>
                {/* Tab panes */}
                <div className="tab-content">
                  <div className="tab-pane  container" id="Login">
                    <button
                      onClick={UserLogin}
                      className="btn btn_man w100 mt-4">
                      <span>Connect Wallet</span>
                    </button>
                  {/* for maintainace mode */}
                    {/* <button className="btn btn_man w100 mt-4">
                      <span>Connect Wallet</span>
                    </button> */}
                  </div>

                  <div className="tab-pane active container" id="register">
                  <div className="mb-4">
                      <label className="text-left w100">Name</label>
                      <input type="text"
                        className="form-control p-4"
                        name="name"
                        value={name}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-4">
                      <label className="text-left w100">Sponser ID</label>
                      <input type="text"
                        className="form-control p-4"
                        name="sponderId"
                        value={sponserId}
                        onChange={handleSponserIdChange}
                      />
                    </div>
                    <div className="mb-4">
                      <label className="text-left w100">
                        Your Wallet Address{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control p-4 "
                        style={{ background: "none" }}
                        value={userAddress}
                        disabled
                      />
                    </div>
                    <button onClick={register} className="btn btn_man w100">
                      <span>Register</span>{" "}
                    </button>
                    {/* <button className="btn btn_man w100">
                      <span>Register</span>{" "}
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
