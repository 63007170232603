import { useEffect, useState } from "react";
import Web3 from "web3";
import {
  ContractAbi,
  contractAddress,
  infuraUrl,
} from "../pages/dashboard/Constant";

// Note: This useEffect for Preview page 

const useIncomeType = (address, trans) => {
  console.log(address, trans);
  const [packageReport, setPackageReport] = useState({
    incomeType: [],
    amount: [],
    fromAddr: [],
    time: [],
    level: [],
  });
  const [sortedDataIds, setSortedDataIds] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!address) return; // Ensure address is present

        const web3init = new Web3(new Web3.providers.HttpProvider(infuraUrl));
        const Trade = new web3init.eth.Contract(ContractAbi, contractAddress);
        const QoraIncome = await Trade.methods.showUserIncomes(address).call();
        const selectedData = {
          incomeType: [],
          amount: [],
          fromAddr: [],
          time: [],
          level: [],
        };

        QoraIncome.incomeType.forEach((incomeType, i) => {
          if (incomeType === `${trans}`) {
            selectedData.incomeType.push(incomeType);
            selectedData.amount.push(QoraIncome.amount[i]);
            selectedData.fromAddr.push(QoraIncome.fromAddr[i]);
            selectedData.time.push(QoraIncome.time[i]);
            selectedData.level.push(QoraIncome.levels[i]);
          }
        });

        setPackageReport(selectedData);

        const uniqueValues = [...new Set(selectedData.fromAddr)];
        // console.log("unique", uniqueValues);

        for (const token of uniqueValues) {
          const userId = await Trade.methods.userIds(token).call();
          const tokenObj = { token, id: userId };
          setSortedDataIds((prevState) => [...prevState, tokenObj]);
          await new Promise((resolve) => setTimeout(resolve, 2000));
        }
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };

    fetchData();
  }, [address]);

  return { packageReport, sortedDataIds };
};

export default useIncomeType;