import React, { useEffect, useState } from 'react'
import { Loader } from '../Loader'
import ReactDatatable from '@mkikets/react-datatable';
import { PreTopbar } from './PreTopbar'
import { PreSidebar } from './PreSidebar';
import Web3 from "web3";
import { useMyContext } from '../../context';
import { ContractAbi, contractAddress, infuraUrl } from '../dashboard/Constant';
import useIncomeType from '../../hooks/useIncomeType';


export const PreQoraReport = () => {
  const [qoraData, setQoraData] = useState([]);
  // const [sortedDataIds,setSortedDataIds] = useState([]);
  const [table, setTable] = useState([]);
  
  const { state } = useMyContext();
  const userAddr = state?.address;
  // Replace with your initial address and trans-type
  const { packageReport, sortedDataIds } = useIncomeType(userAddr, 2);


//   const getQoraDataByIncomeType = async (targetIncomeType)=>
//   {
//     try {
//       const web3init = new Web3(new Web3.providers.HttpProvider(infuraUrl));
//       const userAddr = state?.address;
//       const Trade = new web3init.eth.Contract(ContractAbi, contractAddress);
//       const QoraIncome = await Trade.methods.showUserIncomes(userAddr).call();

//   const selectedData = {
//     incomeType: [],
//     amount: [],
//     fromAddr: [],
//     time: [],
//     level: []
//   };

//   for (let i = 0; i < QoraIncome.incomeType.length; i++) {
//     if (QoraIncome.incomeType[i] === targetIncomeType) {
//       selectedData.incomeType.push(QoraIncome.incomeType[i]);
//       selectedData.amount.push(QoraIncome.amount[i]);
//       selectedData.fromAddr.push(QoraIncome.fromAddr[i]);
//       selectedData.time.push(QoraIncome.time[i]);
//       selectedData.level.push(QoraIncome.levels[i]);
//     }
//   }
//   return selectedData;
// } catch (err) {
//   return null;
// }
//   }

  
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       if (!state.address) return; // Ensure address is present
  //       const web3init = new Web3(new Web3.providers.HttpProvider(infuraUrl));
  //       const Trade = new web3init.eth.Contract(ContractAbi, contractAddress);
  //       const QoraIncome = await Trade.methods
  //         .showUserIncomes(state.address)
  //         .call();
  //       const selectedData = {
  //         incomeType: [],
  //         amount: [],
  //         fromAddr: [],
  //         time: [],
  //         level: [],
  //       };

  //       QoraIncome.incomeType.forEach((incomeType, i) => {
  //         if (incomeType === "2") {
  //           selectedData.incomeType.push(incomeType);
  //           selectedData.amount.push(QoraIncome.amount[i]);
  //           selectedData.fromAddr.push(QoraIncome.fromAddr[i]);
  //           selectedData.time.push(QoraIncome.time[i]);
  //           selectedData.level.push(QoraIncome.levels[i]);
  //         }
  //       });

  //       setQoraData(selectedData);
  //       const uniqueValues = [...new Set(selectedData.fromAddr)];
  //       const values = await Promise.all(
  //         uniqueValues.map(
  //           async (token) => await Trade.methods.userIds(token).call()
  //         )
  //       );
  //       console.log("value",values);
  //       const tokenObj = uniqueValues.map((token, i) => ({
  //         token: token,
  //         id: values[i],
  //       }));
  //       // console.log("tokenObj",tokenObj);
  //       setSortedDataIds(tokenObj);
  //     } catch (err) {
  //       console.error("Error fetching data:", err);
  //     }
  //   };

  //   fetchData();
  // }, [state.address]);



      function formatDateTime(timestamp) {
        const date = new Date(timestamp * 1000);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        const hours = (date.getHours() % 12 || 12).toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
        return `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;
      }
      // let mainTable = [];
      // mainTable = qoraData.amount?.map((amount, index) => ({
      //   amount,
      //   fromAddr: sortedDataIds[index],
      //   time: qoraData.time[index],
      // }));


      useEffect(() => {
        if (sortedDataIds && sortedDataIds.length > 0) {
          const mainTable = packageReport.amount?.map((amount, index) => {
            const address = packageReport.fromAddr[index];
            const id = sortedDataIds.find((token) => token.token === address);
            // console.log(address, "adddress", id.id);
            return {
              amount,
              fromAddr: id ? id.id : "-",
              // fromAddr: packageReport.fromAddr[index],
              time: packageReport.time[index],
            };
          });
          setTable(mainTable);
        }
      }, [sortedDataIds,packageReport]);

    const columns = [
      {
        key: "S.No.",
        text: "S.No.",
        className: "text-white",
        align: "left",
        sortable: true,
        cell: (row, index) => index+1,
      },
      {
        key: "fromAddr",
        text: "fromAddr",
        className: "text-white",
        align: "left",
        sortable: true,
        cell: (row) => {
          return (
              row.fromAddr
          );
          }
        },
      {
        key: "amount",
        text: "Amount",
        className: "text-white",
        align: "left",
        sortable: true,
        cell: (row) => {
          return (
              row.amount/10**6
          );
          }
        },
      {
        key:"date",
        text: "Date Time",
        className: "text-white",
        align: "left",
        sortable: true,
        cell: (row) => {
          return (
              formatDateTime(row.time)
          );
          }
      },
    ];
    const configs = {
      page_size: 10,
      length_menu: [10, 20, 50],
      filename: "country",
      no_data_text: "No user found!",
      button: {
        // excel: true,
        // print: true,
        // csv: true,
      },
      language: {
        length_menu: "Show _MENU_ Record Per Page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      show_info: true,
    };

  return (
    <>
      <div>
          <Loader />
          <PreTopbar />
          <div className="dash_bar_man">
          <PreSidebar />
          <h3 class="fw600 mb-3"> 11 Qore Income Reports</h3>
            <div className="right_bar">
              <div className="row row5">
              <div className="col-md-12">
                  <div className="row ">
                      <div className="col-md-12">
                          <div className="dash_box h100 ">
                          <ReactDatatable
                          config={configs}
                          records={table}
                          columns={columns}
                        />
                          </div>
                      </div>
                  </div>
              </div>
              </div>
            </div>
          </div>
      </div>
    </>
  )
}
