import { useEffect, useState } from 'react';
import Web3 from 'web3';
import { ContractAbi, contractAddress, web3 } from '../pages/dashboard/Constant';

const useAllIncome = (trans) => {
  console.log("add888ress", contractAddress);
  const [packageReport, setPackageReport] = useState({
    incomeType: [],
    amount: [],
    fromAddr: [],
    time: [],
    level: [],
  });
  const [sortedDataIds, setSortedDataIds] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { ethereum } = window;
        const accounts = await ethereum.request({ method: "eth_requestAccounts" });
        const address = accounts[0];
        if (!address) return; // Ensure address is present

        const Trade = new web3.eth.Contract(ContractAbi, contractAddress);
        // const Trade = new web3init.eth.Contract(ContractAbi, contractAddress);
        const QoraIncome = await Trade.methods.showUserIncomes(address).call();
        const selectedData = {
          incomeType: [],
          amount: [],
          fromAddr: [],
          time: [],
          level: [],
        };

        QoraIncome.incomeType.forEach((incomeType, i) => {
          if (incomeType === `${trans}`) {
            selectedData.incomeType.push(incomeType);
            selectedData.amount.push(QoraIncome.amount[i]);
            selectedData.fromAddr.push(QoraIncome.fromAddr[i]);
            selectedData.time.push(QoraIncome.time[i]);
            selectedData.level.push(QoraIncome.levels[i]);
          }
        });

        setPackageReport(selectedData);
        const uniqueValues = [...new Set(selectedData.fromAddr)];

        // const values = await Promise.all(
        //   uniqueValues.map(async (token) => await Trade.methods.userIds(token).call())
        // );

        // const tokenObj = uniqueValues.map((token, i) => ({
        //   token: token,
        //   id: values[i],
        // }));

        // setSortedDataIds(tokenObj);
        for (const token of uniqueValues) {
          const userId = await Trade.methods.userIds(token).call();
          const tokenObj = { token, id: userId };
          setSortedDataIds((prevState) => [...prevState, tokenObj]);
          await new Promise((resolve) => setTimeout(resolve, 2000));
        }
      } catch (err) {
        console.error('Error fetching data:', err);
      }
    };

    fetchData();
  }, []);

  return { packageReport, sortedDataIds };
};

export default useAllIncome;
