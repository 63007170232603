import { ContractAbi, contractAddress, web3 } from "./Constant";

export const getQoraDataByIncomeType = async (targetIncomeType) => {
  try {
    const { ethereum } = window;
    const accounts = await ethereum.request({ method: "eth_requestAccounts" });
    const userAddr = accounts[0];
    const Trade = new web3.eth.Contract(ContractAbi, contractAddress);
    const QoraIncome = await Trade.methods.showUserIncomes(userAddr).call();
    // console.log("QoraIncome",QoraIncome);

    const selectedData = {
      incomeType: [],
      amount: [],
      fromAddr: [],
      time: [],
      level: []
    };

    for (let i = 0; i < QoraIncome.incomeType.length; i++) {
      if (QoraIncome.incomeType[i] === targetIncomeType) {
        selectedData.incomeType.push(QoraIncome.incomeType[i]);
        selectedData.amount.push(QoraIncome.amount[i]);
        selectedData.fromAddr.push(QoraIncome.fromAddr[i]);
        selectedData.time.push(QoraIncome.time[i]);
        selectedData.level.push(QoraIncome.levels[i]);
      }
    }
    return selectedData;
  } catch (err) {
    return null;
  }
};

