import React, { useEffect } from 'react'

export const CopyRight = () => {
  useEffect(() => {
    window.ethereum?.on('accountsChanged', function (accounts) {
      window.location.href = '/';
});
  }, [])
  let objectDate = new Date();
  let year = objectDate.getFullYear();
  
  return (
    <>
        <div className="copyright">
        {year} Life Enjoy. All Rights Reserved
        </div>
    </>
  )
}
