import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

export const Topbar = () => {

  const [userAddr, setUserAddr] = useState("Connected");

  useEffect(() => {
    userInfo()
  }, []);

  const userInfo = async ()=>{
    const {ethereum} = window;
          var accounts = await ethereum?.request({ method: "eth_requestAccounts" });
          if (accounts)
            var userAddr = accounts[0];
            setUserAddr(userAddr);
  }

  return (
    <>
    <div className="top_bar">
    <button  className="navbar-toggler"  type="button" data-toggle="collapse" data-target="#navbarText"> <span className="icon-bar">  <i className="fa fa-bars"></i>   </span>   </button>
          <div className="row align-items-center">
            <div className="col-4">
              <Link to={"/dashboard"} className="navbar-brand">
                <img src="/assets/img/logo.svg" alt="header-Logo" className="logo2" />
              </Link>
            </div>
            <div className="col-auto ml-auto">
            {userAddr==="Connected" ? (
                <Link to="/login" className="btn btn_man"> <span>Connect wallet</span> </Link>
            ) :(
                <Link to="/login" className="btn btn_man"> <span>Connected</span> </Link>
            )}
            </div>
          
          </div>
        </div>
    </>
  )
}
