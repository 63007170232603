import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useMyContext } from '../../context';

export const PreTopbar = () => {

  const [userAddr, setUserAddr] = useState("Connected");
  const { state } = useMyContext();
  const previewAdd = state?.address;


  useEffect(() => {
    userInfo()
  }, []);

  const userInfo = async ()=>{
          if (previewAdd)
            setUserAddr(previewAdd);
  }

  return (
    <>
    <div className="top_bar">
    <button  className="navbar-toggler"  type="button" data-toggle="collapse" data-target="#navbarText"> <span className="icon-bar">  <i className="fa fa-bars"></i>   </span>   </button>
          <div className="row align-items-center">
            <div className="col-4">
              <Link to={"/preview"} className="navbar-brand">
                <img src="/assets/img/logo.svg" alt="header-Logo" className="logo2" />
              </Link>
            </div>
            <div className="col-auto ml-auto">
            {userAddr==="Connected" ? (
                <Link to="/login" className="btn btn_man"> <span>Connect wallet</span> </Link>
            ) :(
                <Link to="/login" className="btn btn_man"> <span>Logout</span> </Link>
            )}
            </div>
          
          </div>
        </div>
    </>
  )
}
