import React, { useEffect, useState } from "react";
import { Loader } from "../Loader";
import { Topbar } from "./Topbar";
import { Sidebar } from "./Sidebar";
import { CopyRight } from "./CopyRight";

import Web3 from "web3";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import {
  ContractAbi,
  chainIdMsg,
  chainIdVal,
  contractAddress,
  usdtTokenAbi,
  usdtTokenAddress,
} from "./Constant";
import { useNavigate } from "react-router-dom";

export const Package = () => {
  const navigate = useNavigate();
  const [buttonText, setButtonText] = useState("Approve");
  const [amount, setAmounts] = useState(0);
  const [myAllowance, setMyAllowance] = useState("");
  const [packageNo, setPackageNo] = useState(0);

  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  // console.log(buttonText, "buttonText");
  // console.log(buttonsDisabled, "buttonsDisabled");
  // console.log(myAllowance,"myAllowance")

  const { ethereum } = window;

  useEffect(() => {
    checkApproval(amount);
    AmountHandler();
    checkPackageNo();
  }, [packageNo, buttonText]);

  useEffect(() => {
    if (buttonText === "Buy Now") {
      setButtonsDisabled(true);
    }
  }, [buttonText]);

  const AmountHandler = (myamt) => {
    setMyAllowance(myamt);
  };

  const handleButtonClick = () => {
    approve();
    setButtonsDisabled(true);
  };

  const checkPackageNo = async () => {
    const { ethereum } = window;
    var accounts = await ethereum?.request({ method: "eth_requestAccounts" });
    if (accounts) {
      var userAddr = accounts[0];
      var web3 = new Web3(window.ethereum);
      var Trade = new web3.eth.Contract(ContractAbi, contractAddress);
      Trade.methods.investors(userAddr).call((error, result) => {
        setPackageNo(result?.currentPackageIndex);
      });
    }
  };

  const deposit = async (amount,packageName, e) => {
    try {
      e.target.disabled = true;
      setAmounts(amount);
      const { ethereum } = window;
      var accounts = await ethereum?.request({ method: "eth_requestAccounts" });
      if (accounts) {
        var userAddr = accounts[0];
        var userInputAmt = amount;
        var web3 = new Web3(window.ethereum);
        var Trade = new web3.eth.Contract(ContractAbi, contractAddress);
        Trade.methods
          .buyPackage(userInputAmt)
          .send({ from: userAddr }, function (err, result) {})
          .then((result) => {
           
            toast.success(`Successfully Package Buy ${packageName}`);
            checkPackageNo();
            setTimeout(()=>{
              window.location.reload();
            },[3000])
          })
          .catch((err) => {
            toast.error(err.message);
            e.target.disabled = false;
            // toast.error(err.message);
            // console.log("hiiii",err);
            // toast.error("Cancelled by user.");
          });
      }
    } catch (err) {
      toast.error(err);
      e.target.disabled = false;
    }
  };

  const checkApproval = async (amount) => {
    try {
      const { ethereum } = window;
      if (!ethereum) {
        toast.error("USDT not found");
        return;
      }
      const chainId = await ethereum.request({ method: "eth_chainId" });
      if (chainId != chainIdVal) {
        toast.error(chainIdMsg);
        return false;
      }
      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });
      const userAddr = accounts[0];
      const web3 = new Web3(window.ethereum);
      const token = new web3.eth.Contract(usdtTokenAbi, usdtTokenAddress);

      let allowance = await token.methods
        .allowance(userAddr, contractAddress)
        .call({ from: userAddr });
        // console.log("allowance",allowance/10**6);
      // allowance = web3.utils.fromWei(allowance, "ether"); // Convert to Ether value
      allowance = allowance / 10**6 // Convert to Ether value
      AmountHandler(allowance);
      setButtonText("Active");
      if (allowance === 0 || parseFloat(allowance) < parseFloat(amount)) {
        setButtonText("Approve");
      } else {
        setButtonText("Buy Now");
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const approve = async () => {
    try {
      const { ethereum } = window;
      var accounts = await ethereum.request({ method: "eth_requestAccounts" });
      var userAddr = accounts[0];
      var web3 = new Web3(window.ethereum);
      var Token = new web3.eth.Contract(usdtTokenAbi, usdtTokenAddress);
      var amt = 350000000000000000000000000; //
      var amount = "0x" + amt.toString(16);
      toast.info("Approving transaction. Please wait...");
      // e.target.disabled = true;
      await Token.methods
        .approve(contractAddress, amount)
        .send({ from: userAddr }, function (err, result) {})
        .then((result) => {
          toast.success("Transaction approved!");
          window.location.reload();
          // e.target.disabled = false;
          // setButtonText("Active");
          setButtonsDisabled(false);
          if (buttonText == "Active") {
            setButtonText("Active");
            setButtonsDisabled(true);
            setButtonText(true);
          }
        })
        .catch((err) => {
          toast.error(err);
          setButtonsDisabled(false);
        });
    } catch (error) {
      toast.error(error);
      setButtonsDisabled(false);
    }
  };

  const AllPackages = [
    { id: 1, Amount: 5, Name: "Start Up" },
    { id: 2, Amount: 10, Name: "Coral" },
    { id: 3, Amount: 20, Name: "Pearl" },

    { id: 4, Amount: 40, Name: "Emerald" },
    { id: 5, Amount: 80, Name: "Sapphire" },
    { id: 6, Amount: 160, Name: "Ruby" },

    { id: 7, Amount: 320, Name: "Jupiter" },
    { id: 8, Amount: 640, Name: "Topaz" },
    { id: 9, Amount: 1280, Name: "Platinum" },
    { id: 10, Amount: 2560, Name: "Royal" },
    { id: 11, Amount: 5120, Name: "Crown" },
  ];

  const [packages] = useState(AllPackages);
  return (
    <>
      <div>
        <Loader />
        <Topbar />
        <div className="dash_bar_man">
          <Sidebar />
          <div className="right_bar">
            <h3 class="fw600 mb-3">Package</h3>
            <div className="row ">
              {packages &&
                packages.map((item) => {
                  return (
                    <div className="col-md-3 mb-3 mb-md-55" key={item?.id}>
                      <div className="dash_box h100 ">
                      {item.id <= Number(packageNo)? <h6 className="d_hadding">
                          {item?.Name} {item?.id} Purchased
                        </h6>:<h6>{item?.Name} {item?.id}</h6> }
                       
                        <h4 className="fw600">{item?.Amount} $</h4>

                        <div className="text-right">
                          {console.log("sfsdffs",packageNo)}
                           {item.id <= Number(packageNo)? (
                            <Button
                              variant="btn btn-success"
                              style={{borderRadius:"10px"}}
                              onClick={handleButtonClick}
                              disabled={buttonsDisabled}
                             
                            >
                              {/* <Button variant="btn btn_man " onClick={approve} disabled={false}> */}
                              <span > Activated </span>
                            </Button>
                          ) : (
                            myAllowance>=item.Amount ?    <Button
                            variant="btn btn_man"
                                   value={item?.Amount}
                                  onClick={(e) => {
                                    e.target.disabled = true;
                                    deposit(item?.Amount,item?.Name, e);
                                  }}
                                  disabled={item.id !== Number(packageNo) + 1}
                                  // disabled={buttonsDisabled}
                                  style={{ backgroundColor: "green" }}
                          >
                            <span> Buy Now </span>
                          </Button> :
                            <Button
                            variant="btn btn_man"
                            onClick={handleButtonClick}
                          >
                            <span> Approve </span>
                          </Button>
                            // <>
                            // {console.log("myAllowance",myAllowance)}
                            //   {myAllowance < item?.Amount ? (
                            //     <Button
                            //       variant="btn btn_man"
                            //       onClick={handleButtonClick}
                            //     >
                            //       <span> Approve </span>
                            //     </Button>
                            //   ) : (

                            //    <>
                            //    {item.id <= Number(packageNo)?
                            //     <Button
                            //       variant="btn btn-success"
                            //       value={item?.Amount}
                            //       onClick={(e) => {
                            //         e.target.disabled = true;
                            //         deposit(item?.Amount, e);
                            //       }}
                            //       disabled={item.id !== Number(packageNo) + 1}
                            //       // disabled={buttonsDisabled}
                            //       style={{ backgroundColor: "green",borderRadius:"10px" }}
                            //     >
                            //     <span > Activated </span>
                               
                            //     </Button>
                            //   : 
                            //   <Button
                            //       variant="btn btn_man"
                            //       value={item?.Amount}
                            //       onClick={(e) => {
                            //         e.target.disabled = true;
                            //         deposit(item?.Amount,item?.Name, e);
                            //       }}
                            //       disabled={item.id !== Number(packageNo) + 1}
                            //       // disabled={buttonsDisabled}
                            //       style={{ backgroundColor: "green" }}
                            //     >
                            //    <span> {buttonText} </span>
                               
                            //     </Button>}  
                              
                            //    </>
                               
                            //   )}
                            // </>
                          )}
                        </div>
                        {/* {console.log(item.id,"buttonText1212",packageNo)} */}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>

          <CopyRight />
        </div>
      </div>
    </>
  );
};
