import React, { useEffect, useState } from 'react'
import { Loader } from '../Loader'
import Web3 from "web3";
import { toast } from 'react-toastify'
import ReactDatatable from '@mkikets/react-datatable';
import { PreTopbar } from './PreTopbar'
import { PreSidebar } from './PreSidebar'
import { useMyContext } from '../../context';
import { ContractAbi, contractAddress, infuraUrl } from '../dashboard/Constant';

export const PrePackgeReport = () => {
  const { state } = useMyContext();

  const [packageReport, setPackageReport] = useState([]);

    const showmecode = (arr) =>{
      var ConvertData = [];
      for(let i = 0; i < arr?.length; i++){
        for(let j = 0; j<arr[i]?.length;j++){
              if(ConvertData[j]===undefined)
              { 
                  ConvertData.push([arr[i][j]]);
              }
              else
              {
                  ConvertData[j].push(arr[i][j]);
              }
          }
      }
   return ConvertData;
}
    useEffect(() => {
        checkPackage()
    }, [])
    const checkPackage = async()=>{
        try {
            const web3init = new Web3(new Web3.providers.HttpProvider(infuraUrl));
            const Trade = new web3init.eth.Contract(ContractAbi, contractAddress);
            const userAddr = state?.address;
            var result = await Trade?.methods?.getPackageDetails(userAddr).call();
            setPackageReport(showmecode([result?.packageAmounts,result?.purchaseTimes]));
  
        } catch (error) {
          toast.error(error);
        }
      }

      function formatDateTime(timestamp) {
        const date = new Date(timestamp * 1000);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        const hours = (date.getHours() % 12 || 12).toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
        return `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;
      }

      let filterTable = packageReport?.map((i)=>{
          return (
            {
               amount:i[0],
               date:i[1]
            }
          )
      })

      const columns = [
        {
          key: "S.No.",
          text: "S.No.",
          className: "text-white",
          align: "left",
          sortable: true,
          cell: (row, index) => index+1,
        },
        {
          key: "amount",
          text: "Amount",
          className: "text-white",
          align: "left",
          sortable: true,
          cell: (row) => {
            return (
                row.amount/10**6
            );
            }
          },
        {
          key:"date",
          text: "Date Time",
          className: "text-white",
          align: "left",
          sortable: true,
          cell: (row) => {
            return (
                formatDateTime(row.date)
            );
            }
        },
      ];
      const configs = {
        page_size: 10,
        length_menu: [10, 20, 50],
        filename: "country",
        no_data_text: "No user found!",
        button: {
          // excel: true,
          // print: true,
          // csv: true,
        },
        language: {
          length_menu: "Show _MENU_ Record Per Page",
          filter: "Filter in records...",
          info: "Showing _START_ to _END_ of _TOTAL_ records",
          pagination: {
            first: "First",
            previous: "Previous",
            next: "Next",
            last: "Last",
          },
        },
        show_length_menu: true,
        show_filter: true,
        show_pagination: true,
        show_info: true,
      };
      

  return (
    <>
    <div>
        <Loader />
        <PreTopbar />
        <div className="dash_bar_man">
        <PreSidebar />
        
        <div className="right_bar">
        <h3 class="fw600 mb-3">Slots Reports</h3>
          
                        <div className="dash_box h100">
                        <ReactDatatable
                          config={configs}
                          records={filterTable}
                          columns={columns}
                        />
                      
            </div>
           
        </div> 
        </div>
    </div>

    </>
  )
}