import React from 'react'
import { Link } from 'react-router-dom'

export const HeroSection = () => {
  return (
    <>
    <div>
        <div className="ecosystem_secrtion p80 text-center " id="token">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-sm-6 mb-4 br_right">
                <div className="ecosystem_box2">
                  <img src="/assets/img/community.png" alt="Community" />
                  <h4>Community</h4>
                  <p>Experience the future of crypto with Our Launchpad, where groundbreaking   projects take flight to revolutionize the world of Blockchain.</p>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 mb-4 br_right">
                <div className="ecosystem_box2">
                  <img src="/assets/img/documentation.png" alt="Documentation" />
                  <h4>Documentation</h4>
                  <p>  We Fuel the growth of cutting-edge crypto startups   in our Incubator,  nurturing innovation and   propelling them towards boundless success.</p>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 mb-4">
                <div className="ecosystem_box2 ">
                  <img src="/assets/img/live_chat.png" alt=" Live chat                        " />
                  <h4>Live chat</h4>
                  <p> Amplify your crypto potential with Multichain IDOs  where innovation has no boundaries and we welcome diverse blockchains to thrive. </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="about_us p80 bg_color" data-scroll-index={3}>
          <div className="container ">
            <div className="row align-items-center">
              <div className="col-md-6 ">
                <div className="washer">
                  <img src="/assets/img/about.png" alt="Launchpad" className="img-fluid rotateme" />
                  <img src="/assets/img/about2.png" alt="Launchpad" className="img-fluid about2" />
                </div>
              </div>
              <div className="col-md-6 ">
                <h2 className="tc hadding">About Life Enjoy</h2>
                <p className>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </p>
                <p className>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
                </p>
              </div>
            </div>
          </div>
        </section>
        <div className="stap3 p80  roadmap overflow_hidden" data-scroll-index={4}>
          <div className="roadmap__image">
            <img decoding="async" src="/assets/img/rocket.png" alt="Onchain Strategy" />
            <div className="roadmap__object circle" />
          </div>
          <div className="container-fluid">
            <div className=" row text-center">
              <div className="col-md-9 m-auto">
                <h2 className=" hadding mb-3">Roadmap</h2>
                <p>Choose your Tier, stake or farm $BRAIN and get access to exclusive token sales and be a part of the revolution.
                  <br /> All tiers will receive a guaranteed allocation.
                </p>
              </div>
            </div>
            <div className="roadmap__wrapper">
              <div className="roadmap__list">
                <div className="roadmap__row">
                  <div className="roadmap__col roadmap__center">
                    <div className="roadmap__item road-item">
                      <div className="road-item__circle road-item__circle-active">
                        <div className="road-item__quart">
                          <p>Q1</p>
                        </div>
                        <div className="road-item__year">
                          <p>2023</p>
                        </div>
                      </div>
                      <div className="road-item__content">
                        <div className="road-item__title">
                          <p>Research and development</p>
                        </div>
                        <div className="road-item__description">
                          <p>Conduct thorough research and development to identify the most promising technologies, platforms, and trends in the crypto and NFT industries.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="roadmap__row">
                  <div className="roadmap__col roadmap__top-left">
                    <div className="roadmap__item road-item">
                      <div className="road-item__circle road-item__circle-active">
                        <div className="road-item__quart">
                          <p>Q2</p>
                        </div>
                        <div className="road-item__year">
                          <p>2023</p>
                        </div>
                      </div>
                      <div className="road-item__content">
                        <div className="road-item__title">
                          <p>Platform development</p>
                        </div>
                        <div className="road-item__description">
                          <p>Build and launch a powerful and user-friendly platform for buying, selling, and trading crypto and NFT assets, with robust security features and a seamless user experience.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="roadmap__col roadmap__bottom-right">
                    <div className="roadmap__item road-item">
                      <div className="road-item__circle road-item__circle-active">
                        <div className="road-item__quart">
                          <p>Q3</p>
                        </div>
                        <div className="road-item__year">
                          <p>2023</p>
                        </div>
                      </div>
                      <div className="road-item__content">
                        <div className="road-item__title">
                          <p>Partner development</p>
                        </div>
                        <div className="road-item__description">
                          <p>Develop key partnerships with industry leaders, influencers, and innovators to expand our reach and strengthen our position as a trusted and respected player in the space.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="roadmap__row">
                  <div className="roadmap__col roadmap__center">
                    <div className="roadmap__item road-item">
                      <div className="road-item__circle road-item__circle-active">
                        <div className="road-item__quart">
                          <p>Q4</p>
                        </div>
                        <div className="road-item__year">
                          <p>2023</p>
                        </div>
                      </div>
                      <div className="road-item__content">
                        <div className="road-item__title">
                          <p>Marketing and outreach</p>
                        </div>
                        <div className="road-item__description">
                          <p>Develop and execute a comprehensive marketing and outreach strategy to attract new users and build a loyal customer base, with a focus on targeted campaigns and community engagement.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="roadmap__row">
                  <div className="roadmap__col roadmap__bottom-left">
                    <div className="roadmap__item road-item">
                      <div className="road-item__circle road-item">
                        <div className="road-item__quart">
                          <p>Q1</p>
                        </div>
                        <div className="road-item__year">
                          <p>2024</p>
                        </div>
                      </div>
                      <div className="road-item__content">
                        <div className="road-item__title">
                          <p>Platform enhancements</p>
                        </div>
                        <div className="road-item__description">
                          <p>Continuously improve and enhance our platform, adding new features and functionality based on user feedback and emerging trends in the crypto and NFT markets.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="roadmap__col roadmap__top-right">
                    <div className="roadmap__item road-item">
                      <div className="road-item__circle road-item">
                        <div className="road-item__quart">
                          <p>Q2</p>
                        </div>
                        <div className="road-item__year">
                          <p>2024</p>
                        </div>
                      </div>
                      <div className="road-item__content">
                        <div className="road-item__title">
                          <p>Regulatory compliance</p>
                        </div>
                        <div className="road-item__description">
                          <p>Stay up-to-date on the latest regulations and compliance requirements in the crypto and NFT industries, and implement strict measures to ensure compliance with all relevant laws and regulations.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="roadmap__row">
                  <div className="roadmap__col roadmap__center">
                    <div className="roadmap__item road-item">
                      <div className="road-item__circle road-item">
                        <div className="road-item__quart">
                          <p>Q3</p>
                        </div>
                        <div className="road-item__year">
                          <p>2024</p>
                        </div>
                      </div>
                      <div className="road-item__content">
                        <div className="road-item__title">
                          <p>Global expansion</p>
                        </div>
                        <div className="road-item__description">
                          <p>Expand our operations and user base globally, with a focus on emerging markets and strategic partnerships with local players in key regions around the world.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ecosystem_secrtion p80 text-center " id="token" data-scroll-index={4}>
          <div className="container">
            <div className=" row mb-4">
              <div className="col-md-9 m-auto text-center">
                <h2 className=" hadding mb-3">Technology of smart contracts and non-fungible tokensy</h2>
                <p>Decentralized marketing is powered by the revolutionary technology of smart contracts and NFTs. The Life Enjoy smart contract code is completely open. You can be sure of its safety and long-term performance
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-6 mb-4">
                <div className="ecosystem_box">
                  <img src="/assets/img/launchpad.png" alt="Launchpad" />
                  <h4>Autonomy</h4>
                  <p>Experience the future of crypto with Our Launchpad, where groundbreaking   projects take flight to revolutionize the world of Blockchain.</p>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 mb-4">
                <div className="ecosystem_box">
                  <img src="/assets/img/unchanging_conditions.png" alt="unchanging_conditions" />
                  <h4>Unchanging Conditions</h4>
                  <p>  We Fuel the growth of cutting-edge crypto startups   in our Incubator,  nurturing innovation and   propelling them towards boundless success.</p>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 mb-4">
                <div className="ecosystem_box">
                  <img src="/assets/img/transparency.png" alt=" Transparency " />
                  <h4>Transparency</h4>
                  <p> Amplify your crypto potential with Multichain IDOs  where innovation has no boundaries and we welcome diverse blockchains to thrive. </p>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 mb-4">
                <div className="ecosystem_box">
                  <img src="/assets/img/full.png" alt="full" />
                  <h4>Full automation</h4>
                  <p>Experience the future of crypto with Our Launchpad, where groundbreaking   projects take flight to revolutionize the world of Blockchain.</p>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 mb-4">
                <div className="ecosystem_box">
                  <img src="/assets/img/decentralization.png" alt="Decentralization" />
                  <h4>Decentralization</h4>
                  <p>  We Fuel the growth of cutting-edge crypto startups   in our Incubator,  nurturing innovation and   propelling them towards boundless success.</p>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 mb-4">
                <div className="ecosystem_box">
                  <img src="/assets/img/online.png" alt="online" />
                  <h4>100% online</h4>
                  <p> Amplify your crypto potential with Multichain IDOs  where innovation has no boundaries and we welcome diverse blockchains to thrive. </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="launch p80 bg_color" data-scroll-index={5}>
          <div className="container ">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="text-center"><img className="img-fluid animation1" src="/assets/img/launch.png" alt="launch" /></div>
              </div>
              <div className="col-lg-6 ">
                <h2 className="tc hadding mt-5 mt-lg-0">The Life Enjoy Community Start From 200+ Countries Around The Globe.</h2>
                <p className="mb-4">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </p>
                <a href="#0" className="btn btn_man"><span>Join Now</span></a>
              </div>
            </div>
          </div>
        </section>
        <section className="faq p80  " data-scroll-index={5}>
          <div className="container ">
            <h2 className="tc hadding mb-2 mb-md-5 text-center">Frequently asked Questions</h2>
            <div className="row">
              <div className="col-lg-10 m-auto">
                <div className="accordion md-accordion style-2" id="accordionEx" role="tablist" aria-multiselectable="true">
                  <div className="card">
                    <div className="card-header" role="tab" id="headingOne1">
                      <a data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne1" aria-expanded="true" aria-controls="collapseOne1">
                        Why should I invest in your project?
                      </a>
                    </div>
                    {/* Card body */}
                    <div id="collapseOne1" className="collapse show " role="tabpanel" aria-labelledby="headingOne1" data-parent="#accordionEx">
                      <div className="card-body">
                        Lorem Ipsum is simply dummy text of the printingLorem Ipsum is simply dummy text of the printingLorem Ipsum is simply dummy text of the printing 
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" role="tab" id="headingTwo2">
                      <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo2" aria-expanded="false" aria-controls="collapseTwo2">
                        How soon can I expect profits after investing? </a>
                    </div>
                    <div id="collapseTwo2" className="collapse" role="tabpanel" aria-labelledby="headingTwo2" data-parent="#accordionEx">
                      <div className="card-body"> Lorem Ipsum is simply dummy text of the printingLorem Ipsum is simply dummy text of the printingLorem Ipsum is simply dummy text of the printingLorem Ipsum is simply dummy text of the printing
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" role="tab" id="headingTwo3">
                      <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo3" aria-expanded="false" aria-controls="collapseTwo3">
                        How is your project different from others? </a>
                    </div>
                    <div id="collapseTwo3" className="collapse" role="tabpanel" aria-labelledby="headingTwo3" data-parent="#accordionEx">
                      <div className="card-body"> Lorem Ipsum is simply dummy text of the printingLorem Ipsum is simply dummy text of the printingLorem Ipsum is simply dummy text of the printingLorem Ipsum is simply dummy text of the printing
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" role="tab" id="headingTwo4">
                      <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo4" aria-expanded="false" aria-controls="collapseTwo4">
                        How secure is my money with your guys? </a>
                    </div>
                    <div id="collapseTwo4" className="collapse" role="tabpanel" aria-labelledby="headingTwo2" data-parent="#accordionEx">
                      <div className="card-body"> Lorem Ipsum is simply dummy text of the printingLorem Ipsum is simply dummy text of the printingLorem Ipsum is simply dummy text of the printingLorem Ipsum is simply dummy text of the printing
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" role="tab" id="headingTwo5">
                      <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo5" aria-expanded="false" aria-controls="collapseTwo5">
                        How would you ensure high amount of profits to the users? </a>
                    </div>
                    <div id="collapseTwo5" className="collapse" role="tabpanel" aria-labelledby="headingTwo5" data-parent="#accordionEx">
                      <div className="card-body"> Lorem Ipsum is simply dummy text of the printingLorem Ipsum is simply dummy text of the printingLorem Ipsum is simply dummy text of the printingLorem Ipsum is simply dummy text of the printingLorem Ipsum is simply dummy text of the printingLorem Ipsum is simply dummy text of the printingLorem Ipsum is simply dummy text of the printing
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" role="tab" id="headingTwo6">
                      <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo6" aria-expanded="false" aria-controls="collapseTwo6">
                        What is crypto MLM? </a>
                    </div>
                    <div id="collapseTwo6" className="collapse" role="tabpanel" aria-labelledby="headingTwo6" data-parent="#accordionEx">
                      <div className="card-body"> Lorem Ipsum is simply dummy text of the printingLorem Ipsum is simply dummy text of the printingLorem Ipsum is simply dummy text of the printingLorem Ipsum is simply dummy text of the printingLorem Ipsum is simply dummy text of the printingLorem Ipsum is simply dummy text of the printing
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="contact padding footer" id="contact" data-scroll-index={6}>
          <div className="container">
            <div className="row">
              <div className="col-md-6  pr-md-5">
                <img src="/assets/img/logo.svg" alt="header-Logo" className="logo mb-3" />
                <p className="mb-4">Discover the future of crypto projects with our cutting-edge    Life Enjoy Launchpad. Get access to exclusive token sales and be a part of the revolution. 
                </p>
                <a href="#0" className="btn btn_man"><span>Whitepaper</span></a>
              </div>
              <div className="col-md-5 pl-md-5 left_border">
                <div className="nav_link mt-3">
                  <h4> Follow Us On</h4>
                  <div className="vertical-social wow fadeInDown  animated " data-wow-delay="0.1s">
                    <ul>
                      <li><Link to="https://www.facebook.com/WWW.LifeEnjoy.io?mibextid=ZbWKwL" target="_blank"> <i className="fa fa-facebook" /></Link></li>
                      <li><Link to="https://t.me/lifeenjoy100" target="_blank"><i className="fa fa-telegram" /></Link></li>
                      {/* <li><Link to="#" target="_blank"> <img src="/assets/img/discord.png" alt="discord" /></Link></li> */}
                      <li><Link to="https://www.youtube.com/@LifeEnjoy.io23" target="_blank"><i className="fa fa-youtube" /></Link></li>
                      <li><Link to="https://instagram.com/lifeenjoy.io?igshid=NzZlODBkYWE4Ng==" target="_blank"><i className="fa fa-instagram" /></Link></li>
                    </ul>
                  </div>
                  <div className="nav_box ">
                    <h4> Never Miss any Update.</h4>
                    <div className="never_news mt-3">
                      <input placeholder="Enter your mail address" />
                      <button className="btn btn_man"><span><i className="fa fa-paper-plane" /></span></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright">
            <div className="container">
              <div className="row">
                <div className="col-md-6 order-sm-2 text-sm-right">
                  <a href="#0">Privacy Policy </a>
                  <a className="ml-2 ml-md-4" href="#0"> Terms of Services </a>
                </div>
                <div className="col-md-6 order-sm-1">
                  © 2023 Life Enjoy. All Rights Reserved
                </div>
              </div>
            </div>
        </div></section>
    </div>
    </>
  )
}
