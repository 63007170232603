// MyContext.js
import React, { createContext, useReducer, useContext } from 'react';

// Create a context with an initial state
const MyContext = createContext();

// Create a provider component that will wrap your app
export const MyContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(myReducer, initialState);

  return (
    <MyContext.Provider value={{ state, dispatch }}>
      {children}
    </MyContext.Provider>
  );
};

// Create a custom hook for accessing the context
export const useMyContext = () => {
  return useContext(MyContext);
};

// Define your initial state and reducer function
const initialState = {
    // address: localStorage.getItem('previewAddress') ? localStorage.getItem('previewAddress'):null,
    address: localStorage.getItem('previewAddress') || null,
    // Other state properties...
  };

const myReducer = (state, action) => {
  // Your state update logic here
  switch (action.type) {
    case 'SET_ADDRESS':
      localStorage.setItem('previewAddress', action.payload);
      return {
        ...state,
        address: action.payload,
      };
    case 'REMOVE_ADDRESS':
      localStorage.removeItem('previewAddress');
      return {
        ...state,
        address: null,
      };
    default:
      return state;
  }
};
