import { Loader } from '../Loader'
import { PreTopbar } from './PreTopbar'
import { PreSidebar } from './PreSidebar'
import React, { useState } from 'react'


const PreCalculator = () => {
    const [selectedSlot, setSelectedSlot] = useState(1);
    const [slotAmount, setslotAmount] = useState(5);
    const [levelIncome, setlevelIncome] = useState(409.40);
    const [directIncome, setdirectIncome] = useState(12282);
    const [QouraIncome, setQouraIncome] = useState(409.10);
    const [TotalIncome, setTotalIncome] = useState(13100.50);
    const slots = [1,2,3,4,5,6,7,8,9,10,11];

    const  handleClick = (clickSlot) => {
        setSelectedSlot(clickSlot);

       var currectSlot = 5 * 2 ** clickSlot - 5;
       setslotAmount(currectSlot);
       const accu = currectSlot/5;
      const  finalQuara = accu*409.10;
      const  finalLevel = accu*409.40;
      const finalDirectIncome = accu*12282;
      const grandTotal = finalQuara+finalLevel+finalDirectIncome;
       setQouraIncome(finalQuara);
       setlevelIncome(finalLevel);
       setdirectIncome(finalDirectIncome);
       setTotalIncome(grandTotal);

    };
    return (
        <>
            <div>
                <Loader />
                <PreTopbar />
                <div className="dash_bar_man">
                    <PreSidebar />

                    <div className="right_bar">
                        <h3 class="fw600 mb-3">Life Enjoy Participant Calculator </h3>
                        <div className="dash_box p-md-4">
                        <div className="row  align-items-center">
                        <div className="col-md-4 mb-md-0 mb-4">
                          <div className="d-flex boxs flex-wrap calculator_box">

                          {slots.map((item, index) => (
                            <div key={index}
                                onClick={(e) => handleClick(item)}
                                className={`d-flex boxs_in ${item <= selectedSlot ? 'active' : ''}`}>
                                <span>{item}</span>
                            </div>
                            ))}
                            </div>
                            </div>
                            <div className="col-md-4">
                            <div class="mb-4">
                                <h6 className="d_hadding mb-2">Cost of all selected slots </h6>
                                <h4 className="fw600">{slotAmount} $</h4>
                            </div>
                            <div class="mb-4 mb-md-0">
                                <h6 className="d_hadding mb-2">Total Income  </h6>
                                <h4 className="fw600">{(TotalIncome).toFixed(2)} $</h4>
                            </div>
                            </div>

                            <div className="col-md-4">
                            <div class="mb-4">
                                <h6 className="d_hadding mb-2">Direct Income</h6>
                                <h4 className="fw600">{(directIncome).toFixed(2)} $</h4>
                            </div>
                            
                            <div class="mb-4">
                                <h6 className="d_hadding mb-2">Level Income </h6>
                                <h4 className="fw600">{(levelIncome).toFixed(2)} $</h4>
                            </div>
                            <div class="">
                                <h6 className="d_hadding mb-2">11 Qore Income </h6>
                                <h4 className="fw600">{(QouraIncome).toFixed(2)} $</h4>
                            </div>
                            </div>
                           
                            </div>
                        </div>

                    </div> 
                </div>
            </div>
        </>
    )
}

export default PreCalculator