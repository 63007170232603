import React, { useEffect, useState } from "react";
import { Loader } from "../Loader";

// import { CopyRight } from "./CopyRight";
import { Spinners } from "../partials/Spinners";
import Web3 from "web3";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { PreSidebar } from "./PreSidebar";
import { useMyContext } from "../../context";
import { PreTopbar } from "./PreTopbar";
import { ContractAbi, contractAddress, infuraUrl, usdtTokenAbi, usdtTokenAddress } from "../dashboard/Constant";

const nullToken = '0x0000000000000000000000000000000000000000';

export const PreTree = () => {
  const navigate = useNavigate();
  const { state } = useMyContext();
  const { id } = useParams();
  const [buttonText, setButtonText] = useState("Approve");
  const [amount, setAmounts] = useState(0);
  const [myAllowance, setMyAllowance] = useState("");
  const [packageNo, setPackageNo] = useState(0);
  const [mainTree, setMainTree] = useState([]);
  const [packageAmt, setPackageAmt] = useState(1);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [spinnerss, setSpinnerss] = useState(true);
  const [activeClass, setActiveClass] = useState("btn btn_man");
  // console.log(spinnerss,"spinnerss1")
  const web3init = new Web3(new Web3.providers.HttpProvider(infuraUrl));
  var  previewAdd = state?.address;
  useEffect(() => {
    checkApproval(amount);
    AmountHandler();
  }, [packageNo, buttonText]);
  useEffect(() => {
    checkPackageNo();
  }, [packageAmt]);

  const AmountHandler = (myamt) => {
    setMyAllowance(myamt);
  };

  const handleButtonClick = () => {
    approve();
    setButtonsDisabled(true);
  };
  const handlePackageClick = (amount) => {
    setPackageAmt(amount);
    setActiveClass("btn btn_man active"); // Set the active class for the clicked button

    // Your other logic here
  };

  const checkPackageNo = async () => {
    setSpinnerss(true)
    try {
      if (previewAdd) {
        const userAddr = previewAdd;
        var Trade = new web3init.eth.Contract(ContractAbi, contractAddress);
        let arr;
  
        if (id == "user") {
          arr = [userAddr];
        } else {
          
          const userAddrNew=id!=nullToken? await Trade?.methods.addressById(id).call():userAddr;
          arr = [userAddrNew];
        }
  
        const dl = await Trade.methods
        .getUserDirectListLength(userAddr)
        .call();
        
        let directs = await Trade.methods
        .getTopDirectList(arr[0],dl).call();
        let mainArr = [];
  
        for (let i = 0; i < 5; i++) {
          const newArr = [];
  
          for (let j = 0; j < arr?.length; j++) {
            const userAddr = arr[j];
            // console.log("USGD",userAddr);
            try {
              
              const result = await Trade?.methods
                .packageinvestors(packageAmt, userAddr)
                .call();
  
              newArr?.push(result?.leftUser, result?.rightUser);
              const userData=userAddr!=nullToken?await Trade?.methods
              .userIds(userAddr).call():userAddr;
  
              const leftid=result?.leftUser!=nullToken?await Trade?.methods
              .userIds(result.leftUser).call():result.leftUser;
  
              const rightid=result.rightUser!=nullToken?await Trade?.methods
              .userIds(result.rightUser).call():result.rightUser; 
  
              mainArr?.push({
                root: userData,
                leftUser: leftid,
                rightUser: rightid,
                directleft:directs.includes(result.leftUser),
                directright:directs.includes(result.rightUser)
              });
              setTimeout(()=>{
                setSpinnerss(false)
              },1000)
            } catch (error) {
              console.error("Error calling the contract:", error);
            }
          }
  
          arr = newArr;
        }
  
        // The arr variable now contains the results from all the iterations
        console.log("Final arr:", mainArr);
        setMainTree(mainArr);
      }
    } catch (error) {
        console.log(error.message);
    }
  };

  const deposit = async (amount, e) => {
    try {
      e.target.disabled = true;
      setAmounts(amount);
      const userAddr = previewAdd;
      var Trade = new web3init.eth.Contract(ContractAbi, contractAddress);
      if (userAddr) {
        var userInputAmt = amount;
        // var web3 = new Web3(window.ethereum);
        var Trade = new web3init.eth.Contract(ContractAbi, contractAddress);
        Trade.methods
          .buyPackage(userInputAmt)
          .send({ from: userAddr }, function (err, result) {})
          .then((result) => {
            toast.success("Successfully Package Buy.");
            checkPackageNo();
          })
          .catch((err) => {
            toast.error(err);
            e.target.disabled = false;
            toast.error("Cancelled by user.");
          });
      }
    } catch (err) {
      toast.error(err);
      e.target.disabled = false;
    }
  };

  const checkApproval = async (amount) => {
    try {
     
      const userAddr = previewAdd;
      const token = new web3init.eth.Contract(usdtTokenAbi, usdtTokenAddress);

      let allowance = await token.methods
        .allowance(userAddr, contractAddress)
        .call({ from: userAddr });
      allowance = web3init.utils.fromWei(allowance, "ether"); // Convert to Ether value
      AmountHandler(allowance);
      setButtonText("Active");
      if (allowance === 0 || parseFloat(allowance) < parseFloat(amount)) {
        setButtonText("Approve");
      } else {
        setButtonText("Activated");
      }
    } catch (error) {
      toast.error(error);
    }
  };

  function MouseOver(event) {
    event.target.classList.add('active');
  }
  function MouseOut(event){
    event.target.classList.remove('active');
  }

  const approve = async () => {
    try {
    //   var accounts = await ethereum.request({ method: "eth_requestAccounts" });
    //   var userAddr = accounts[0];
    //   var web3 = new Web3(window.ethereum);
    const userAddr = previewAdd;
      const Token = new web3init.eth.Contract(usdtTokenAbi, usdtTokenAddress);
      
      var amt = 350000000000000000000000000;
      var amount = "0x" + amt.toString(16);
      toast.info("Approving transaction. Please wait...");
      // e.target.disabled = true;
      await Token.methods
        .approve(contractAddress, amount)
        .send({ from: userAddr }, function (err, result) {})
        .then((result) => {
          toast.success("Transaction approved!");
          // e.target.disabled = false;
          // setButtonText("Active");
          setButtonsDisabled(false);
          if (buttonText == "Active") {
            setButtonText("Activated");
            setButtonText(true);
          }
        })
        .catch((err) => {
          toast.error(err);
          setButtonsDisabled(false);
        });
    } catch (error) {
      toast.error(error);
      setButtonsDisabled(false);
    }
  };

  const AllPackages = [
    { id: 1, Amount: 5, Name: "Start Up" },
    { id: 2, Amount: 10, Name: "Coral" },
    { id: 3, Amount: 20, Name: "Pearl" },

    { id: 4, Amount: 40, Name: "Emerald" },
    { id: 5, Amount: 80, Name: "Sapphire" },
    { id: 6, Amount: 160, Name: "Ruby" },

    { id: 7, Amount: 320, Name: "Jupiter" },
    { id: 8, Amount: 640, Name: "Topaz" },
    { id: 9, Amount: 1280, Name: "Platinum" },
    { id: 10, Amount: 2560, Name: "Royal" },
    { id: 11, Amount: 5120, Name: "Crown" },
  ];

  const [packages] = useState(AllPackages);



  return (
    <>
      <div>
        <Loader />
        <PreTopbar/>
        <div className="dash_bar_man">
          <PreSidebar/>
          <div className="right_bar">
            <h3 className="fw600 mb-3">Tree</h3>
            <div className="white_box">
              <div className="tab-btn">
                <button
                  className={
                    packageAmt == "1" ? "btn btn_man active" : "btn btn_man"
                  }
                  onClick={() => handlePackageClick(1)}
                >
                 <span> 5 USDT</span>
                </button>
                <button
                  className={
                    packageAmt == "2" ? "btn btn_man active" : "btn btn_man"
                  }
                  onClick={() => handlePackageClick(2)}
                >
                 <span> 10 USDT</span>
                </button>
                <button
                  className={
                    packageAmt == "3" ? "btn btn_man active" : "btn btn_man"
                  }
                  onClick={() => handlePackageClick(3)}
                >
                 <span> 20 USDT</span>
                </button>

                <button
                  className={
                    packageAmt == "4" ? "btn btn_man active" : "btn btn_man"
                  }
                  onClick={() => handlePackageClick(4)}
                >
                 <span> 40 USDT</span>
                </button>
                <button
                  className={
                    packageAmt == "5" ? "btn btn_man active" : "btn btn_man"
                  }
                  onClick={() => handlePackageClick(5)}
                >
                 <span> 80 USDT</span>
                </button>
                <button
                  className={
                    packageAmt == "6" ? "btn btn_man active" : "btn btn_man"
                  }
                  onClick={() => handlePackageClick(6)}
                >
                 <span> 160 USDT</span>
                </button>

                <button
                  className={
                    packageAmt == "7" ? "btn btn_man active" : "btn btn_man"
                  }
                  onClick={() => handlePackageClick(7)}
                >
                 <span> 320 USDT</span>
                </button>
                <button
                  className={
                    packageAmt == "8" ? "btn btn_man active" : "btn btn_man"
                  }
                  onClick={() => handlePackageClick(8)}
                >
                 <span> 640 USDT</span>
                </button>
                <button
                  className={
                    packageAmt == "9" ? "btn btn_man active" : "btn btn_man"
                  }
                  onClick={() => handlePackageClick(9)}
                >
                 <span> 1280 USDT</span>
                </button>
                <button
                  className={
                    packageAmt == "10" ? "btn btn_man active" : "btn btn_man"
                  }
                  onClick={() => handlePackageClick(10)}
                >
                 <span> 2560 USDT</span>
                </button>
                <button
                  className={
                    packageAmt == "11" ? "btn btn_man active" : "btn btn_man"
                  }
                  onClick={() => handlePackageClick(11)}
                >
                <span>  5120 USDT</span>
                </button>
              </div>
              {spinnerss ? <Spinners /> : <div className="tree mb-4">
                <ul>
                  <li>
                    {mainTree.slice(0, 1).map((list, index) => {
                      return (
                        <>
                          <a onMouseOver={MouseOver} onMouseOut={MouseOut} className={list.root !== nullToken ? 'root-user' : 'li-inactive'}
                            href={list.root !== nullToken ? `/preview-tree/${list.root==0?1:list.root}` : '#'}
                            title={list.root==0?1:list.root} >
                            {<i class="fa fa-user"></i>}
                          </a>

                          <ul>
                            {list.leftUser &&
                              "0x0000000000000000000000000000000000000000" ? (
                              <li>
                                <a className={list.leftUser !== nullToken ? (list.directleft ? 'root-user' : 'li-active') : 'li-inactive'}
                                  href={list.leftUser !== nullToken ? `/preview-tree/${list.leftUser}` : '#'}
                                  title={list.leftUser !== nullToken ? list.leftUser : '0'} >
                                  {<i class="fa fa-user"></i>}
                                </a>
                                <ul>
                                  {mainTree.length >= 1 &&
                                    mainTree
                                      .slice(1, 2)
                                      .map((list2, index2) => {
                                        return (
                                          <>
                                            {list2.leftUser &&
                                              "0x0000000000000000000000000000000000000000" && (
                                                <li >

                                                  <a className={list2.leftUser !== nullToken ? (list2.directleft ? 'root-user' : 'li-active') : 'li-inactive'} href={list2.leftUser !== nullToken ? `/preview-tree/${list2.leftUser}` : '#'}
                                                    title={list2.leftUser !== nullToken ? list2.leftUser : '0'}>
                                                    {<i class="fa fa-user"></i>}
                                                  </a>

                                                  <ul>
                                                    {mainTree.length >= 3 &&
                                                      mainTree
                                                        .slice(3, 4)
                                                        .map((list3, index2) => {
                                                          return (
                                                            <>
                                                              {list3.leftUser &&
                                                                "0x0000000000000000000000000000000000000000" && (
                                                                  <li>
                                                                    <a className={list3.leftUser !== nullToken ? (list3.directleft ? 'root-user' : 'li-active') : 'li-inactive'}
                                                                      href={list3.leftUser !== nullToken ? `/preview-tree/${list3.leftUser}` : '#'}
                                                                      title={
                                                                        list3.leftUser !== nullToken ? list3.leftUser : '0'
                                                                      }
                                                                    >
                                                                      {<i class="fa fa-user"></i>}
                                                                    </a>
                                                                    <ul>
                                                                      {mainTree.length >=
                                                                        7 &&
                                                                        mainTree
                                                                          .slice(
                                                                            7,
                                                                            8
                                                                          )
                                                                          .map(
                                                                            (
                                                                              list4,
                                                                              index2
                                                                            ) => {
                                                                              return (
                                                                                <>
                                                                                  {list4.leftUser &&
                                                                                    "0x0000000000000000000000000000000000000000" && (
                                                                                      <li>

                                                                                        <a className={list4.leftUser !== nullToken ? (list4.directleft ? 'root-user' : 'li-active') : 'li-inactive'}
                                                                                          href={list4.leftUser !== nullToken ? `/preview-tree/${list4.leftUser}` : '#'}
                                                                                          title={
                                                                                            list4.leftUser !== nullToken ? list4.leftUser : '0'
                                                                                          }
                                                                                        >
                                                                                          {<i class="fa fa-user"></i>}
                                                                                        </a>
                                                                                      </li>
                                                                                    )}
                                                                                  {list4.rightUser &&
                                                                                    "0x0000000000000000000000000000000000000000" && (
                                                                                      <li>
                                                                                        <a className={list4.rightUser !== nullToken ? (list4.directright ? 'root-user' : 'li-active') : 'li-inactive'}
                                                                                          href={list4.rightUser !== nullToken ? `/preview-tree/${list4.rightUser}` : '#'}
                                                                                          title={
                                                                                            list4.rightUser !== nullToken ? list4.rightUser : '0'
                                                                                          }
                                                                                        >
                                                                                          {<i class="fa fa-user"></i>}
                                                                                        </a>
                                                                                      </li>
                                                                                    )}
                                                                                </>
                                                                              );
                                                                            }
                                                                          )}
                                                                    </ul>
                                                                  </li>
                                                                )}
                                                              {list3.rightUser &&
                                                                "0x0000000000000000000000000000000000000000" && (
                                                                  <li>
                                                                    <a className={list3.rightUser !== nullToken ? (list3.directright ? 'root-user' : 'li-active') : 'li-inactive'}
                                                                      href={list3.rightUser !== nullToken ? `/preview-tree/${list3.rightUser}` : '#'}
                                                                      title={
                                                                        list3.rightUser !== nullToken ? list3.rightUser : '0'
                                                                      } >
                                                                      {<i class="fa fa-user"></i>}
                                                                    </a>
                                                                    <ul>
                                                                      {mainTree.length >=
                                                                        8 &&
                                                                        mainTree
                                                                          .slice(
                                                                            8,
                                                                            9
                                                                          )
                                                                          .map(
                                                                            (
                                                                              list5,
                                                                              index2
                                                                            ) => {
                                                                              return (
                                                                                <>
                                                                                  {list5.leftUser &&
                                                                                    "0x0000000000000000000000000000000000000000" && (
                                                                                      <li>
                                                                                        <a className={list5.leftUser !== nullToken ? (list5.directleft ? 'root-user' : 'li-active') : 'li-inactive'}
                                                                                          href={list5.leftUser !== nullToken ? `/preview-tree/${list5.leftUser}` : '#'}
                                                                                          title={
                                                                                            list5.leftUser !== nullToken ? list5.leftUser : '0'
                                                                                          }
                                                                                        >
                                                                                          {<i class="fa fa-user"></i>}
                                                                                        </a>
                                                                                      </li>
                                                                                    )}
                                                                                  {list5.rightUser &&
                                                                                    "0x0000000000000000000000000000000000000000" && (
                                                                                      <li>
                                                                                        <a className={list5.rightUser !== nullToken ? (list5.directright ? 'root-user' : 'li-active') : 'li-inactive'}
                                                                                          href={list5.rightUser !== nullToken ? `/preview-tree/${list5.rightUser}` : '#'}
                                                                                          title={
                                                                                            list5.rightUser !== nullToken ? list5.rightUser : '0'
                                                                                          }
                                                                                        >
                                                                                          {<i class="fa fa-user"></i>}
                                                                                        </a>
                                                                                      </li>
                                                                                    )}
                                                                                </>
                                                                              );
                                                                            }
                                                                          )}
                                                                    </ul>
                                                                  </li>
                                                                )}
                                                            </>
                                                          );
                                                        })}
                                                  </ul>
                                                </li>
                                              )}
                                            {list2.rightUser &&
                                              "0x0000000000000000000000000000000000000000" && (
                                                <li>
                                                  <a className={list2.rightUser !== nullToken ? (list2.directright ? 'root-user' : 'li-active') : 'li-inactive'}
                                                    href={`/preview-tree/${list2.rightUser}`}
                                                    title={
                                                      list2.rightUser !== nullToken ? list2.rightUser : '0'
                                                    }
                                                  >
                                                    {<i class="fa fa-user"></i>}
                                                  </a>
                                                  <ul>
                                                    {mainTree.length >= 4 &&
                                                      mainTree
                                                        .slice(4, 5)
                                                        .map((list4, index2) => {
                                                          return (
                                                            <>
                                                              {list4.leftUser &&
                                                                "0x0000000000000000000000000000000000000000" && (
                                                                  <li>
                                                                    <a className={list4.leftUser !== nullToken ? (list4.directleft ? 'root-user' : 'li-active') : 'li-inactive'}
                                                                      href={list4.leftUser !== nullToken ? `/preview-tree/${list4.leftUser}` : '#'}
                                                                      title={
                                                                        list4.leftUser !== nullToken ? list4.leftUser : '0'
                                                                      }
                                                                    >
                                                                      {<i class="fa fa-user"></i>}
                                                                    </a>
                                                                    <ul>
                                                                      {mainTree.length >=
                                                                        9 &&
                                                                        mainTree
                                                                          .slice(
                                                                            9,
                                                                            10
                                                                          )
                                                                          .map(
                                                                            (
                                                                              list5,
                                                                              index2
                                                                            ) => {
                                                                              return (
                                                                                <>
                                                                                  {list5.leftUser &&
                                                                                    "0x0000000000000000000000000000000000000000" && (
                                                                                      <li>
                                                                                        <a className={list5.leftUser !== nullToken ? (list5.directleft ? 'root-user' : 'li-active') : 'li-inactive'}
                                                                                          href={list5.leftUser !== nullToken ? `/preview-tree/${list5.leftUser}` : '#'}
                                                                                          title={
                                                                                            list5.leftUser !== nullToken ? list5.leftUser : '0'
                                                                                          }
                                                                                        >
                                                                                          {<i class="fa fa-user"></i>}
                                                                                        </a>
                                                                                      </li>
                                                                                    )}
                                                                                  {list5.rightUser &&
                                                                                    "0x0000000000000000000000000000000000000000" && (
                                                                                      <li>
                                                                                        <a className={list5.rightUser !== nullToken ? (list5.directright ? 'root-user' : 'li-active') : 'li-inactive'}
                                                                                          href={list5.rightUser !== nullToken ? `/preview-tree/${list5.rightUser}` : '#'}
                                                                                          title={
                                                                                            list5.rightUser !== nullToken ? list5.rightUser : '0'
                                                                                          }
                                                                                        >
                                                                                          {<i class="fa fa-user"></i>}
                                                                                        </a>
                                                                                      </li>
                                                                                    )}
                                                                                </>
                                                                              );
                                                                            }
                                                                          )}
                                                                    </ul>
                                                                  </li>
                                                                )}
                                                              {list4.rightUser &&
                                                                "0x0000000000000000000000000000000000000000" && (
                                                                  <li>
                                                                    <a className={list4.rightUser !== nullToken ? (list4.directright ? 'root-user' : 'li-active') : 'li-inactive'}
                                                                      href={list4.rightUser !== nullToken ? `/preview-tree/${list4.rightUser}` : '#'}
                                                                      title={
                                                                        list4.rightUser !== nullToken ? list4.rightUser : '0'
                                                                      }
                                                                    >
                                                                      {<i class="fa fa-user"></i>}
                                                                    </a>
                                                                    <ul>
                                                                      {mainTree.length >=
                                                                        10 &&
                                                                        mainTree
                                                                          .slice(
                                                                            10,
                                                                            11
                                                                          )
                                                                          .map(
                                                                            (
                                                                              list5,
                                                                              index2
                                                                            ) => {
                                                                              return (
                                                                                <>
                                                                                  {list5.leftUser &&
                                                                                    "0x0000000000000000000000000000000000000000" && (
                                                                                      <li>
                                                                                        <a className={list5.leftUser !== nullToken ? (list5.directleft ? 'root-user' : 'li-active') : 'li-inactive'}
                                                                                          href={list5.leftUser !== nullToken ? `/preview-tree/${list5.leftUser}` : '#'}
                                                                                          title={
                                                                                            list4.leftUser !== nullToken ? list5.leftUser : '0'
                                                                                          }
                                                                                        >
                                                                                          {<i class="fa fa-user"></i>}
                                                                                        </a>
                                                                                      </li>
                                                                                    )}
                                                                                  {list5.rightUser &&
                                                                                    "0x0000000000000000000000000000000000000000" && (
                                                                                      <li>
                                                                                        <a className={list5.rightUser !== nullToken ? (list5.directright ? 'root-user' : 'li-active') : 'li-inactive'}
                                                                                          href={list5.rightUser !== nullToken ? `/preview-tree/${list5.rightUser}` : '#'}
                                                                                          title={
                                                                                            list5.rightUser !== nullToken ? list5.rightUser : '0'
                                                                                          }
                                                                                        >
                                                                                          {<i class="fa fa-user"></i>}
                                                                                        </a>
                                                                                      </li>
                                                                                    )}
                                                                                </>
                                                                              );
                                                                            }
                                                                          )}
                                                                    </ul>
                                                                  </li>
                                                                )}
                                                            </>
                                                          );
                                                        })}
                                                  </ul>
                                                </li>
                                              )}
                                          </>
                                        );
                                      })}
                                </ul>
                              </li>
                            ) : (
                              ""
                            )}
                            {list.rightUser &&
                              "0x0000000000000000000000000000000000000000" && (
                                <li>
                                  <a className={list.rightUser !== nullToken ? (list.directright ? 'root-user' : 'li-active') : 'li-inactive'}
                                    href={list.rightUser !== nullToken ? `/preview-tree/${list.rightUser}` : '#'}
                                    title={
                                      list.rightUser !== nullToken ? list.rightUser : '0'
                                    }
                                  >
                                    {<i class="fa fa-user"></i>}

                                  </a>
                                  <ul>
                                    {mainTree.length >= 2 &&
                                      mainTree
                                        .slice(2, 3)
                                        .map((list2, index2) => {
                                          return (
                                            <>
                                              {list2.leftUser &&
                                                "0x0000000000000000000000000000000000000000" && (
                                                  <li>
                                                    <a className={list2.leftUser !== nullToken ? (list2.directleft ? 'root-user' : 'li-active') : 'li-inactive'}
                                                      href={list2.leftUser !== nullToken ? `/preview-tree/${list2.leftUser}` : '#'}
                                                      title={
                                                        list2.leftUser !== nullToken ? list2.leftUser : '0'
                                                      }
                                                    >
                                                      {<i class="fa fa-user"></i>}
                                                    </a>
                                                    <ul>
                                                      {mainTree.length >= 5 &&
                                                        mainTree
                                                          .slice(5, 6)
                                                          .map((list3, index2) => {
                                                            return (
                                                              <>
                                                                {list3.leftUser &&
                                                                  "0x0000000000000000000000000000000000000000" && (
                                                                    <li>
                                                                      <a className={list3.leftUser !== nullToken ? (list3.directleft ? 'root-user' : 'li-active') : 'li-inactive'}
                                                                        href={list3.leftUser !== nullToken ? `/preview-tree/${list3.leftUser}` : '#'}
                                                                        title={
                                                                          list3.leftUser !== nullToken ? list3.leftUser : '0'
                                                                        }
                                                                      >
                                                                        {<i class="fa fa-user"></i>}
                                                                      </a>
                                                                      <ul>
                                                                        {mainTree.length >=
                                                                          11 &&
                                                                          mainTree
                                                                            .slice(
                                                                              11,
                                                                              12
                                                                            )
                                                                            .map(
                                                                              (
                                                                                list5,
                                                                                index2
                                                                              ) => {
                                                                                return (
                                                                                  <>
                                                                                    {list5.leftUser &&
                                                                                      "0x0000000000000000000000000000000000000000" && (
                                                                                        <li>
                                                                                          <a className={list5.leftUser !== nullToken ? (list5.directleft ? 'root-user' : 'li-active') : 'li-inactive'}
                                                                                            href={list5.leftUser !== nullToken ? `/preview-tree/${list5.leftUser}` : '#'}
                                                                                            title={
                                                                                              list5.leftUser !== nullToken ? list5.leftUser : '0'
                                                                                            }
                                                                                          >
                                                                                            {<i class="fa fa-user"></i>}
                                                                                          </a>
                                                                                        </li>
                                                                                      )}
                                                                                    {list5.rightUser &&
                                                                                      "0x0000000000000000000000000000000000000000" && (
                                                                                        <li>
                                                                                          <a className={list5.rightUser !== nullToken ? (list5.directright ? 'root-user' : 'li-active') : 'li-inactive'}
                                                                                            href={list5.rightUser !== nullToken ? `/preview-tree/${list5.rightUser}` : '#'}
                                                                                            title={

                                                                                              list5.rightUser !== nullToken ? list5.rightUser : '0'
                                                                                            }
                                                                                          >
                                                                                            {<i class="fa fa-user"></i>}
                                                                                          </a>
                                                                                        </li>
                                                                                      )}
                                                                                  </>
                                                                                );
                                                                              }
                                                                            )}
                                                                      </ul>
                                                                    </li>
                                                                  )}
                                                                {list3.rightUser &&
                                                                  "0x0000000000000000000000000000000000000000" && (
                                                                    <li>
                                                                      <a className={list3.rightUser !== nullToken ? (list3.directright ? 'root-user' : 'li-active') : 'li-inactive'}
                                                                        href={list3.rightUser !== nullToken ? `/preview-tree/${list3.rightUser}` : '#'}
                                                                        title={
                                                                          list3.rightUser !== nullToken ? list3.rightUser : '0'
                                                                        }
                                                                      >
                                                                        {<i class="fa fa-user"></i>}
                                                                      </a>
                                                                      <ul>
                                                                        {mainTree.length >=
                                                                          12 &&
                                                                          mainTree
                                                                            .slice(
                                                                              12,
                                                                              13
                                                                            )
                                                                            .map(
                                                                              (
                                                                                list5,
                                                                                index2
                                                                              ) => {
                                                                                return (
                                                                                  <>
                                                                                    {list5.leftUser &&
                                                                                      "0x0000000000000000000000000000000000000000" && (
                                                                                        <li>
                                                                                          <a className={list5.leftUser !== nullToken ? (list5.directleft ? 'root-user' : 'li-active') : 'li-inactive'}

                                                                                            href={list5.leftUser !== nullToken ? `/preview-tree/${list5.leftUser}` : '#'}
                                                                                            title={
                                                                                              list5.leftUser !== nullToken ? list5.leftUser : '0'
                                                                                            }
                                                                                          >
                                                                                            {<i class="fa fa-user"></i>}
                                                                                          </a>
                                                                                        </li>
                                                                                      )}
                                                                                    {list5.rightUser &&
                                                                                      "0x0000000000000000000000000000000000000000" && (
                                                                                        <li>
                                                                                          <a className={list5.rightUser !== nullToken ? (list5.directright ? 'root-user' : 'li-active') : 'li-inactive'}
                                                                                            href={list5.rightUser !== nullToken ? `/preview-tree/${list5.rightUser}` : '#'}
                                                                                            title={
                                                                                              list5.rightUser !== nullToken ? list5.rightUser : '0'
                                                                                            }
                                                                                          >
                                                                                            {<i class="fa fa-user"></i>}

                                                                                          </a>
                                                                                        </li>
                                                                                      )}
                                                                                  </>
                                                                                );
                                                                              }
                                                                            )}
                                                                      </ul>
                                                                    </li>
                                                                  )}
                                                              </>
                                                            );
                                                          })}
                                                    </ul>
                                                  </li>
                                                )}
                                              {list2.rightUser &&
                                                "0x0000000000000000000000000000000000000000" && (
                                                  <li>
                                                    <a className={list2.rightUser !== nullToken ? (list2.directright ? 'root-user' : 'li-active') : 'li-inactive'}
                                                      href={list2.rightUser !== nullToken ? `/preview-tree/${list2.rightUser}` : '#'}
                                                      title={
                                                        list2.rightUser !== nullToken ? list2.rightUser : '0'
                                                      }>
                                                      {<i class="fa fa-user"></i>}
                                                    </a>
                                                    <ul>
                                                      {mainTree.length >= 6 &&
                                                        mainTree
                                                          .slice(6, 7)
                                                          .map((list4, index2) => {
                                                            return (
                                                              <>
                                                                {list4.leftUser &&
                                                                  "0x0000000000000000000000000000000000000000" && (
                                                                    <li>
                                                                      <a className={list4.leftUser !== nullToken ? (list4.directleft ? 'root-user' : 'li-active') : 'li-inactive'}

                                                                        href={list4.leftUser !== nullToken ? `/preview-tree/${list4.leftUser}` : '#'}
                                                                        title={
                                                                          list4.leftUser !== nullToken ? list4.leftUser : '0'
                                                                        }
                                                                      >
                                                                        {<i class="fa fa-user"></i>}
                                                                      </a>
                                                                      <ul>
                                                                        {mainTree.length >=
                                                                          13 &&
                                                                          mainTree
                                                                            .slice(
                                                                              13,
                                                                              14
                                                                            )
                                                                            .map(
                                                                              (
                                                                                list5,
                                                                                index2
                                                                              ) => {
                                                                                return (
                                                                                  <>
                                                                                    {list5.leftUser &&
                                                                                      "0x0000000000000000000000000000000000000000" && (
                                                                                        <li>
                                                                                          <a className={list5.leftUser !== nullToken ? (list5.directleft ? 'root-user' : 'li-active') : 'li-inactive'}
                                                                                            href={list5.leftUser !== nullToken ? `/preview-tree/${list5.leftUser}` : '#'}
                                                                                            title={
                                                                                              list5.leftUser !== nullToken ? list5.leftUser : '0'
                                                                                            }
                                                                                          >
                                                                                            {<i class="fa fa-user"></i>}
                                                                                          </a>
                                                                                        </li>
                                                                                      )}
                                                                                    {list5.rightUser &&
                                                                                      "0x0000000000000000000000000000000000000000" && (
                                                                                        <li>
                                                                                          <a className={list5.rightUser !== nullToken ? (list5.directright ? 'root-user' : 'li-active') : 'li-inactive'}
                                                                                            href={list5.rightUser !== nullToken ? `/preview-tree/${list5.rightUser}` : '#'}
                                                                                            title={
                                                                                              list5.rightUser !== nullToken ? list5.rightUser : '0'
                                                                                            }
                                                                                          >
                                                                                            {<i class="fa fa-user"></i>}
                                                                                          </a>
                                                                                        </li>
                                                                                      )}
                                                                                  </>
                                                                                );
                                                                              }
                                                                            )}
                                                                      </ul>
                                                                    </li>
                                                                  )}
                                                                {list4.rightUser &&
                                                                  "0x0000000000000000000000000000000000000000" && (
                                                                    <li>
                                                                      <a className={list4.rightUser !== nullToken ? (list4.directright ? 'root-user' : 'li-active') : 'li-inactive'}
                                                                        href={list4.rightUser !== nullToken ? `/preview-tree/${list4.rightUser}` : '#'}
                                                                        title={
                                                                          list4.rightUser !== nullToken ? list4.rightUser : '0'
                                                                        }
                                                                      >
                                                                        {<i class="fa fa-user"></i>}
                                                                      </a>
                                                                      <ul>
                                                                        {mainTree.length >=
                                                                          14 &&
                                                                          mainTree
                                                                            .slice(
                                                                              14,
                                                                              15
                                                                            )
                                                                            .map(
                                                                              (
                                                                                list5,
                                                                                index2
                                                                              ) => {
                                                                                return (
                                                                                  <>
                                                                                    {list5.leftUser &&
                                                                                      "0x0000000000000000000000000000000000000000" && (
                                                                                        <li>
                                                                                          <a className={list5.leftUser !== nullToken ? (list5.directleft ? 'root-user' : 'li-active') : 'li-inactive'}
                                                                                            href={list5.leftUser !== nullToken ? `/preview-tree/${list5.leftUser}` : '#'}
                                                                                            title={

                                                                                              list5.leftUser !== nullToken ? list5.leftUser : '0'
                                                                                            }
                                                                                          >
                                                                                            {<i class="fa fa-user"></i>}
                                                                                          </a>
                                                                                        </li>
                                                                                      )}

                                                                                    {list5.rightUser &&
                                                                                      "0x0000000000000000000000000000000000000000" && (
                                                                                        <li>
                                                                                          <a className={list5.rightUser !== nullToken ? (list5.directright ? 'root-user' : 'li-active') : 'li-inactive'}
                                                                                            href={list5.rightUser !== nullToken ? `/preview-tree/${list5.rightUser}` : '#'}
                                                                                            title={
                                                                                              list5.rightUser !== nullToken ? list5.rightUser : '0'
                                                                                            }
                                                                                          >
                                                                                            {
                                                                                              <i class="fa fa-user"></i>
                                                                                            }

                                                                                          </a>
                                                                                        </li>
                                                                                      )}

                                                                                  </>
                                                                                );
                                                                              }
                                                                            )}
                                                                      </ul>
                                                                    </li>
                                                                  )}
                                                              </>
                                                            );
                                                          })}
                                                    </ul>
                                                  </li>
                                                )}
                                            </>
                                          );
                                        })}
                                  </ul>
                                </li>
                              )}
                          </ul>
                        </>
                      );
                    })}
                  </li>
                </ul>
              </div>}
              
            </div>
          </div>

        </div>
      </div>
    </>
  );
};
