import React from 'react'

export const Video = () => {
  return (
    <>
     <video className="video-wrap"playsInline="playsinline"autoPlay="autoplay"  muted="muted" loop="loop">
          <source src="assets/video.mp4" type="video/mp4"/>
        </video>
    </>
  )
}
