import React, { useEffect, useState } from 'react'
import { Loader } from '../Loader'
import { Topbar } from './Topbar'
import { Sidebar } from './Sidebar'
import { CopyRight } from './CopyRight'
import ReactDatatable from '@mkikets/react-datatable';
import useAllIncome from '../../hooks/useAllIncome'

export const QoraReport = () => {
  // const [qoraData, setQoraData] = useState([]);
  const { packageReport, sortedDataIds } = useAllIncome(1);
  const [table, setTable] = useState([]);

  function formatDateTime(timestamp) {
    const date = new Date(timestamp * 1000);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = (date.getHours() % 12 || 12).toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
    return `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;
  }

  useEffect(() => {
    if (sortedDataIds && sortedDataIds.length > 0) {
      const mainTable = packageReport.amount?.map((amount, index) => {
        const address = packageReport.fromAddr[index];
        const id = sortedDataIds.find((token) => token.token === address);
        // console.log(address, "adddress", id.id);
        return {
          amount,
          fromAddr: id ? id.id : "-",
          // fromAddr: packageReport.fromAddr[index],
          time: packageReport.time[index],
          // level: packageReport?.level[index],
        };
      });
      setTable(mainTable);
    }
  }, [sortedDataIds, packageReport]);

      
      // let mainTable = [];
      // mainTable = qoraData.amount?.map((amount, index) => ({
      //   amount,
      //   fromAddr: sortedDataIds[index],
      //   // fromAddr: qoraData.fromAddr[index],
      //   time: qoraData.time[index],
      // }));

    const columns = [
      {
        key: "S.No.",
        text: "S.No.",
        className: "text-white",
        align: "left",
        sortable: true,
        cell: (row, index) => index+1,
      },
      {
        key: "fromAddr",
        text: "User ID",
        className: "text-white",
        align: "left",
        sortable: true,
        cell: (row) => {
          return (
              row.fromAddr
          );
          }
        },
      {
        key: "amount",
        text: "Amount",
        className: "text-white",
        align: "left",
        sortable: true,
        cell: (row) => {
          return (
              row.amount/10**6
          );
          }
        },
      {
        key:"date",
        text: "Date Time",
        className: "text-white",
        align: "left",
        sortable: true,
        cell: (row) => {
          return (
              formatDateTime(row.time)
          );
          }
      },
    ];
    const configs = {
      page_size: 10,
      length_menu: [10, 20, 50],
      filename: "country",
      no_data_text: "No user found!",
      button: {
        // excel: true,
        // print: true,
        // csv: true,
      },
      language: {
        length_menu: "Show _MENU_ Record Per Page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      show_info: true,
    };

  return (
    <>
      <div>
          <Loader />
          <Topbar />
          <div className="dash_bar_man">
          <Sidebar />
          <h3 class="fw600 mb-3"> 11 Qore Income Reports</h3>
            <div className="right_bar">
              <div className="row row5">
              <div className="col-md-12">
                  <div className="row ">
                      <div className="col-md-12">
                          <div className="dash_box h100 ">
                          <ReactDatatable
                          config={configs}
                          records={table}
                          columns={columns}
                        />
                          </div>
                      </div>
                  </div>
              </div>
              </div>
              <CopyRight />
            </div>
          </div>
      </div>
    </>
  )
}
