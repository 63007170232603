import React, { useEffect, useState } from "react";
import { Loader } from "../Loader";
import { Topbar } from "./Topbar";
import { Sidebar } from "./Sidebar";
import { CopyRight } from "./CopyRight";
import { ContractAbi, contractAddress, web3 } from "./Constant";
import ReactDatatable from '@mkikets/react-datatable';
import { toast } from "react-toastify";

export const RoyaltyReport = () => {
  const [packageReport, setPackageReport] = useState([]);

  useEffect(() => {
    checkPackage();
  }, []);

  const checkPackage = async () => {
    try {
      const { ethereum } = window;
      var accounts = await ethereum.request({ method: "eth_requestAccounts" });
      var userAddr = accounts[0];
      var Trade = new web3.eth.Contract(ContractAbi, contractAddress);

      let arr = [];
      var AmtLength = await Trade.methods
        .getUserDirectListLength(userAddr)
        .call();

      if (AmtLength > 0) {
        const result = {
          Address: [],
        };

        for (let i = 0; i < AmtLength; i++) {
          var userDirectList = await Trade.methods
            .userDirectList(userAddr, i)
            .call();

          result.Address.push(userDirectList);
          arr.push(result);
        }
      }

      let arr2 = [];
      let obj1 = {};
      if (arr?.length > 0) {
        for (let i = 0; i < arr.length; i++) {
          const InvestorData = await Trade.methods
            .investors(arr[i].Address[0])
            .call();
          obj1 = {
            walletAddress: arr[i].Address[i],
            totalBusinesss: InvestorData.totalBusiness,
          };
          arr2.push(obj1);
        }
      }

      const sortedData = [...arr2].sort((a, b) => {
        return parseInt(a.totalBusinesss) - parseInt(b.totalBusinesss);
      });

      const result = sortedData.reduce((acc, obj) => {
        const existingItem = acc.find(
          (x) => x.walletAddress === obj.walletAddress
        );
        if (existingItem) {
          existingItem.totalBusinesss += Number(obj.totalBusinesss);
        } else {
          acc.push({
            walletAddress: obj.walletAddress,
            totalBusinesss: Number(obj.totalBusinesss),
          });
        }
        return acc;
      }, []);
      setPackageReport(result);
    } catch (err) {
      toast.error(err);
    }
  };
  
  let filterTable = packageReport?.map((i)=>{
    return (
      {
        walletAddress:i.walletAddress,
        totalBusinesss:i.totalBusinesss,
      }
      )
    })

  const columns = [
    {
      key: "S.No.",
      text: "S.No.",
      className: "text-white",
      align: "left",
      sortable: true,
      cell: (row,index) => index+1,
    },
    {
      key: "walletAddress",
      text: "Referral Address",
      className: "text-white",
      align: "left",
      sortable: true,
      cell: (row, index) => row.walletAddress,
    },
    {
      key: "totalBusinesss",
      text: "Amount",
      className: "text-white",
      align: "left",
      sortable: true,
      cell: (row) => {
        return (
            row.totalBusinesss/(10 ** 6).toFixed(2)
        );
        }
      },
  ];

  const configs = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "country",
    no_data_text: "No user found!",
    button: {
      // excel: true,
      // print: true,
      // csv: true,
    },
    language: {
      length_menu: "Show _MENU_ Record Per Page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  return (
    <>
      <div>
        <Loader />
        <Topbar />
        <div className="dash_bar_man">
          <Sidebar />
          <h3 class="fw600 mb-3">Royalty Income Reports</h3>
          <div className="right_bar">
            <div className="row row5">
              <div className="col-md-12">
                <div className="row ">
                  <div className="col-md-12">
                    <div className="dash_box h100 ">
                    <ReactDatatable
                          config={configs}
                          records={filterTable}
                          columns={columns}
                        />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <CopyRight />
          </div>
        </div>
      </div>
    </>
  );
};
