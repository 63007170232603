import React from 'react'

export const Loader = () => {
  return (
    <>
        <div id="loader">
            <div className="loader1">
                <img src="/assets/img/brain.png" alt="header-Logo" />
            </div>
        </div>
    </>
  )
}
