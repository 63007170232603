import React from "react";
import { Header } from "../common/Header";
import { Navbar } from "../common/Navbar";
import { HeroSection } from "../common/HeroSection";
import { Loader } from "./Loader";
import { Video } from "./Video";
import { Link } from "react-router-dom";
import CountdownTimer from "./CountdownTimer";
// import { Footer } from "../common/Footer";

export const Home = () => {  
  return (
    <div>
      <div>

        <Loader/>
        <Video/>
        <Navbar/>

        <div className="modal fade" id="Community">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="pr-4 pt-4">
                <button type="button" className="close" data-dismiss="modal">
                  ×
                </button>
              </div>
 
              <div className="modal-body p-md-4 text-center ">
                <div className="nav_link mb-3">
                  <h4> Follow Us On</h4>
                  <div className="vertical-social  mt-4" data-wow-delay="0.1s">
                    <ul>
                      <li>
                        <Link to="#" target="_blank">
                          {" "}
                          <i className="fa fa-twitter" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#" target="_blank">
                          <i className="fa fa-telegram" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#" target="_blank">
                          {" "}
                          <img src="/assets/img/discord.png" alt="discord" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#" target="_blank">
                          <i className="fa fa-youtube" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#" target="_blank">
                          <i className="fa fa-medium" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Header/>

        {/* <CountdownTimer /> */}
        <HeroSection/>
        
      </div>
    </div>
  );
};
