import React, { useEffect, useState } from "react";
import { Loader } from "../Loader";
import { Topbar } from "./Topbar";
import { Sidebar } from "./Sidebar";
import { CopyRight } from "./CopyRight";
import { contractAddress, ContractAbi, web3 } from "./Constant";
import moment from 'moment'
import Web3 from "web3";
import ReactDatatable from "@mkikets/react-datatable";

export const Parteners = () => {
  const [qoraData, setQoraData] = useState([]);
  useEffect(() => {
    checkPackageNo()
  }, [])
  

  const checkPackageNo = async () => {
    const { ethereum } = window;
    const accounts = await ethereum?.request({ method: "eth_requestAccounts" });

    if (accounts) {
      const userAddr = accounts[0];
      const web3 = new Web3(window.ethereum);
      const Trade = new web3.eth.Contract(ContractAbi, contractAddress);
    
      // const result = await Trade.methods
      // .getLastPackageAmount( userAddr)
      // .call();
      // const firstPackageBuy = await Trade.methods
      // .getPackageDetails( userAddr)
      // .call();
      // const userId = await Trade.methods
      // .userIds( userAddr)
      // .call();
      //  let time=new Date(firstPackageBuy.purchaseTimes[0]*1000)
 
      //  {
      //   user_id:userId,
      //   wallet_address:userAddr,
      //   package_slot:result/10**6,
      //   date:moment(time).format("DD-MM-YYYY")
      //  }
       let arr=[]

      let directAddress=await Trade.methods
      .getAllDirectReferrals( userAddr)
      .call();
             console.log(directAddress,"directAddress")
      for(let i=0;i<directAddress.length;i++){
        const result = await Trade.methods
        .getLastPackageAmount( directAddress[i])
        .call();
        const firstPackageBuy = await Trade.methods
        .getPackageDetails( directAddress[i])
        .call();
        const userId = await Trade.methods
        .userIds( directAddress[i])
        .call();
         let time=new Date(firstPackageBuy.purchaseTimes[0]*1000)
         arr.push({
          user_id:userId,
          wallet_address:directAddress[i],
          package_slot:result/10**6,
          date:moment(time).format("DD-MM-YYYY")
         })
      }


       setQoraData(arr)
    
    }
  };

  const columns = [
    {
      key: "S.No.",
      text: "S.No.",
      className: "text-white",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "user_id",
      text: "User ID",
      className: "text-white",
      align: "left",
      sortable: true,
     
    },
    // {
    //   key: "wallet_address",
    //   text: "Wallet Address",
    //   className: "text-white",
    //   align: "left",
    //   sortable: true,
   
    // },
    {
      key: "package_slot",
      text: "Package Slot",
      className: "text-white",
      align: "left",
      sortable: true,

    },
    {
      key: "date",
      text: "Date Of Join",
      className: "text-white",
      align: "left",
      sortable: true,
      //   cell: (row) => {
      //     return formatDateTime(row.time);
      //   },
    },
  ];
  const configs = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "country",
    no_data_text: "No user found!",
    button: {
      // excel: true,
      // print: true,
      // csv: true,
    },
    language: {
      length_menu: "Show _MENU_ Record Per Page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  return (
    <div>
      <Loader />
      <Topbar />
      <div className="dash_bar_man">
        <Sidebar />
        <h3 class="fw600 mb-3"> Partners Income Reports</h3>
        <div className="right_bar">
          <div className="row row5">
            <div className="col-md-12">
              <div className="row ">
                <div className="col-md-12">
                  <div className="dash_box h100 ">
                    <ReactDatatable
                      config={configs}
                        records={qoraData}
                      columns={columns}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <CopyRight />
        </div>
      </div>
    </div>
  );
};