import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import { TypeAnimation } from "react-type-animation";
import { useMyContext } from '../context';
import Web3 from 'web3';
import { ContractAbi, adminAddress, contractAddress, infuraUrl } from '../pages/dashboard/Constant';
import { toast } from 'react-toastify';
import { Loader } from '../pages/Loader';

export const Header = () => {
  const { state, dispatch } = useMyContext();
  const[previewAddress, setPreviewAddress] = useState();
  const navigate = useNavigate();

  const web3init = new Web3(new Web3.providers.HttpProvider(infuraUrl));

  const  handleSubmit  = async (event) =>{
    event.preventDefault();
    
    const Trade = new web3init.eth.Contract(ContractAbi, contractAddress);
        
        let userAddr = await Trade?.methods?.addressById(previewAddress).call();
         const isAddressValid = web3init.utils.isAddress(userAddr);
         
         if (isAddressValid) {
                 const Trade = new web3init.eth.Contract(ContractAbi, contractAddress);
                 Trade.methods
                   .investors(userAddr)
                   .call((error, result) => {})
                   .then((result) => {
                     toast.success("Please wait processing...");
                     if (Boolean(result.registered === true)) {
                       setTimeout(() => {
                         // Save the input value to localStorage
                         // localStorage.setItem('previewAddress', previewAddress); 
                         dispatch({ type: 'SET_ADDRESS', payload: userAddr });
                       navigate("/preview");
             
                       }, 2000);
                     } else {
                       toast.error("Wallet Address in not registered.");
                     }
                   });
         } else {
                 toast.error("Admin id not show start from id 2.");
         }

  }
  
  return (
    <>
      <header className="home" id="home" data-scroll-index={1}>
        <div className="Background_container__KTfIm">
          <div className="Background_RectL__oAOXA" />
          <div className="Background_ElipL__xhjwR" />
        </div>
        <div className="container">
          <h1 className="mb text-center">UNLEASHING THE POWER OF<br />
            <span className="type1">
              <TypeAnimation sequence={[
                1000, // wait 1s before replacing "Mice" with "Hamsters"
                "LIFE ENJOY",
                1000,
              ]}
                wrapper="span"
                speed={50}
                repeat={Infinity}
              />
            </span>
          </h1>

          <div className="row align-items-center">
            <div className="col-md-3 ml-auto">
              <img alt='img' src="/assets/img/brain.png" className=" img-fluid " />
            </div>
            <div className="col-md-7 pl-md-5">
              <h4 className=" mb-3 ">New Unique Program</h4>
              <div className="login_btns">
                <Link to="/login" className="btn btn_man"> <span>Registration</span> </Link>
                {/* <div className="btn btn_man"> <span>Registration</span> </div> */}
              </div>
              <form onSubmit={handleSubmit}>
          <div className="mt-4 row mb-md-4">
            <label className="text-left w100 col-md-12 mb-2">
            Access a preview of any life enjoy<br/> community member account by entering the user id.{" "}
            </label>
            <div className="col-md-6"><input
              type="text"
              name='preview'
              placeholder='enter user id'
              value={previewAddress}
              className="form-control"
              required
              style={{
                background: "none",
                borderColor: "rgb(255 255 255 / 60%)",
                padding: 20
              }}
              onChange={(e) => {setPreviewAddress(e.target.value)}}
            /></div>
            <div className="col-md-4 mt-3 mt-md-0">
            <button type='submit' className="btn btn_man ">
            <span>Preview</span>
          </button>
            </div>
            
          </div>
          
        </form>
            </div>
          </div>

        
        </div>
      </header>


    </>
  )
}
